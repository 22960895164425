<template>
  <div class="home">
    <div class="save-buttons-container">
      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="saveImage($refs.table_desktop, 2.9)"
      >
        Guardar POST
      </button>

      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="
          saveImageDirect('https://wa.cambiocuba.money/media_informal.png')
        "
      >
        Guardar Buy/Sell
      </button>

      <router-link class="ml-4 underline" to="/crypto-rates"
        >Ir a las criptomonedas</router-link
      >

      <p class="mt-3">
        Fecha y hora en la Habana en el servidor:
        <span
          v-if="showHavanaDate()"
          :class="{ 'red-text': !isCurrentDatePassSevenAm }"
          >{{ havanaDateTimeString }}</span
        >
      </p>
      <p>
        <span
          v-if="showHavanaDate() && !isCurrentDatePassSevenAm"
          class="red-text"
          >Hora no recomendada para establecer la tasa correspondiente a
          {{ fecha_establecida }}</span
        >
      </p>
    </div>

    <div class="first-images-row w-full flex gap-1">
      <section
        class="
          flex flex-col
          gap-16
          table-desktop table-section
          w-full
          max-w-5xl
          p-8
        "
        ref="table_desktop"
      >
        <header
          class="table-section-header w-full flex flex-col items-center gap-4"
        >
          <div
            class="
              date-container
              flex flex-row
              gap-2
              helvetica
              font-thin
              text-red-500
              border-b border-gray-900
            "
          >
            <span>06</span>
            <span class="text-gray-900">/</span>
            <span class="">Febrero</span>
            <span class="text-gray-900">/</span>
            <span>2024</span>
          </div>

          <!-- <div>
            <img
                class="w-full"
                src="@/assets/pork-h1.png"
                alt="Precio del puerco hoy"
              />
          </div> -->

          <div class="w-full flex flex-col items-center gap-2 text-center">
            <p class="font-bold rubik-distressed text-6xl text-red-500">
              PRECIO DEL CERDO
            </p>
            <div class="flex">
              <p class="font-bold rubik-distressed text-6xl text-red-500">
                EN LA HABANA
              </p>
              <img
                class="w-20 h-20"
                src="@/assets/pork-stamp.png"
                alt="Puerco sello"
              />
            </div>
            <p class="font-bold rubik-distressed text-6xl text-red-500">HOY</p>
          </div>
        </header>

        <div class="grid grid-cols-2 gap-4 items-center">
          <ul class="w-full uppercase text-gray-900 helvetica-bold text-4xl">
            <li class="w-full py-5 border-b-2 border-gray-900">
              <div class="flex items-center justify-between">
                <span>Lomo</span>
                <span>$200</span>
              </div>
            </li>
            <li class="w-full py-5 border-b-2 border-gray-900">
              <div class="flex items-center justify-between">
                <span>Lomo</span>
                <span>$200</span>
              </div>
            </li>
            <li class="w-full py-5 border-b-2 border-gray-900">
              <div class="flex items-center justify-between">
                <span>Lomo</span>
                <span>$200</span>
              </div>
            </li>
            <li class="w-full py-5 border-b-2 border-gray-900">
              <div class="flex items-center justify-between">
                <span>Lomo</span>
                <span>$200</span>
              </div>
            </li>
          </ul>

          <img class="" src="@/assets/pork-map.png" alt="Puerco mapa" />
        </div>

        <footer
          class="
            flex flex-col
            items-center
            justify-center
            gap-4
            border-t-2 border-gray-900
            py-2
          "
        >
          <div class="helvetica text-lg text-indigo-400">
            <p>* Los valores hacen referencia a 1lb. del producto en CUP</p>
          </div>
          <img src="@/assets/logo-rosa.png" alt="Puerco mapa" />
        </footer>
      </section>
    </div>
    <div class="mt-20">
      <img id="img-pork-desktop" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import domtoimage from "dom-to-image-font-patch";
import FileSaver from "file-saver";

export default {
  name: "PorkTablesView",
  mounted() {
    console.log("mounted");
  },
  data() {
    console.log("data");
    return {};
  },
  computed: {
    havanaDateTime() {
      const date = new Date();
      console.log(date);
      return date;
    },
    havanaDateTimeString() {
      return `${this.havanaDateTime?.toLocaleString() ?? ""}`;
    },
    isCurrentDatePassSevenAm() {
      return !(
        this.havanaDateTime?.getTime() <
        new Date(this.fecha_establecida).getTime()
      );
    },
  },
  methods: {
    showHavanaDate() {
      return this.havanaDate !== "";
    },
    saveImageDirect(url) {
      FileSaver.saveAs(url, "mediana_compra_venta.png");
    },
    saveImage(table, scale, show, id) {
      domtoimage
        .toBlob(table, {
          quality: 1,
          height: table.clientHeight * scale,
          width: table.clientWidth * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
          },
        })
        .then(function (blob) {
          if (show) {
            const objectURL = URL.createObjectURL(blob);
            const myImage = document.querySelector(`#${id}`);
            myImage.src = objectURL;
          } else {
            FileSaver.saveAs(blob, "TRMI.png");
          }
        });
    },
  },
};
</script>

<!-- <style>
section {
  background-color: #f3e3e4 !important;
}

.table-section-header {
  background-color: transparent !important;
}
</style> -->
