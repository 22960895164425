import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import ECharts from "vue-echarts";
import { use } from "echarts/core";
import "tailwindcss/tailwind.css";
import easySpinner from "vue-easy-spinner";

// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from "echarts/renderers";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  MarkPointComponent,
  VisualMapComponent,
  ToolboxComponent,
  DataZoomComponent,
  MarkLineComponent,
} from "echarts/components";
import {
  LineChart,
  CandlestickChart,
  BarChart,
} from "echarts/charts";
import LitepieDatepicker from "litepie-datepicker";

use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  MarkPointComponent,
  CandlestickChart,
  BarChart,
  VisualMapComponent,
  ToolboxComponent,
  DataZoomComponent,
  MarkLineComponent,
]);

const app = createApp(App)
  .use(store)
  .use(router)
  .use(LitepieDatepicker)
  .use(easySpinner, {
    /*options*/
    prefix: "easy",
  });
// register globally (or you can do it locally)
app.component("v-chart", ECharts);
app.mount("#app");
