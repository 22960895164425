"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.OFFER_TYPE =
  exports.OFFER_MARKET =
  exports.ExchangeV2 =
  exports.ExchangeV1 =
    void 0;

var _axios = _interopRequireDefault(require("axios"));

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const OFFER_TYPE = {
  BUY: "Compra",
  SELL: "Venta",
  ALL: null,
};
exports.OFFER_TYPE = OFFER_TYPE;
const OFFER_MARKET = {
  EXTERNAL: "Si",
  INTERNAL: "No",
  ALL: null,
};
exports.OFFER_MARKET = OFFER_MARKET;

class Exchange {
  constructor(apiVersion) {
    this.url = `https://api.cambiocuba.money/api/${apiVersion}`;
  }

  validateParams(params) {
    if (
      params.fromDate &&
      !(0, _moment.default)(params.fromDate, "YYYY-MM-DD").isValid()
    ) {
      throw {
        error: 'Wrong param "fromDate"',
        message: "Invalid Date",
      };
    }

    if (
      params.toDate &&
      !(0, _moment.default)(params.toDate, "YYYY-MM-DD").isValid()
    ) {
      throw {
        error: 'Wrong param "toDate"',
        message: "Invalid Date",
      };
    }

    if (
      params.ofDate &&
      !(0, _moment.default)(params.ofDate, "YYYY-MM-DD").isValid()
    ) {
      throw {
        error: 'Wrong param "ofDate"',
        message: "Invalid Date",
      };
    }

    if (
      params.offerType &&
      !Object.values(OFFER_TYPE).includes(params.offerType)
    ) {
      throw {
        error: 'Wrong param "offerType"',
        message: "offerType have to be one of OFFER_TYPE enum",
      };
    }

    if (
      params.offerMarket &&
      !Object.values(OFFER_MARKET).includes(params.offerMarket)
    ) {
      throw {
        error: 'Wrong param "offerMarket"',
        message: "offerMarket have to be one of OFFER_MARKET enum",
      };
    }

    if (params.period && !/^[\d]+[dmyDMY]$/g.test(params.period)) {
      throw {
        error: 'Wrong param "period"',
        message: "period have to be like, 1D, 5M or 1Y",
      };
    }

    if (params.countDaysBefore && typeof params.countDaysBefore !== "number") {
      throw {
        error: 'Wrong param "countDaysBefore"',
        message: "countDaysBefore have to be positive integer",
      };
    }
  }

  getRateByCurrency({
    fromDate,
    toDate,
    offerType = OFFER_TYPE.ALL,
    offerMarket = OFFER_MARKET.ALL,
    showMessages = true,
    baseCurrency,
  } = {}) {
    try {
      this.validateParams({
        fromDate,
        toDate,
        offerType,
        offerMarket,
        showMessages,
        baseCurrency,
      });
      const params = {
        date_from: fromDate
          ? (0, _moment.default)(fromDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD hh:mm:ss"
            )
          : fromDate,
        date_to: toDate
          ? (0, _moment.default)(toDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD hh:mm:ss"
            )
          : toDate,
        offer: offerType,
        is_extern_offer: offerMarket,
        msg: showMessages,
        x_cur: baseCurrency
          ? baseCurrency == "all"
            ? "all"
            : `${baseCurrency}`.toUpperCase()
          : null,
        token:"aCY78gC3kWRv1pR7VfgSlg"
      };
      console.log("getting from API:", baseCurrency);
      return _axios.default.get(`${this.url}/x-rates`, {
        params: params,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  getRateByPaymentMethods({
    fromDate,
    toDate,
    offerType = OFFER_TYPE.ALL,
    offerMarket = OFFER_MARKET.ALL,
    showMessages = true,
    baseCurrency,
  } = {}) {
    try {
      this.validateParams({
        fromDate,
        toDate,
        offerType,
        offerMarket,
        showMessages,
        baseCurrency,
      });
      const params = {
        date_from: fromDate
          ? (0, _moment.default)(fromDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD hh:mm:ss"
            )
          : fromDate,
        date_to: toDate
          ? (0, _moment.default)(toDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD hh:mm:ss"
            )
          : toDate,
        offer: offerType,
        is_extern_offer: offerMarket,
        msg: showMessages,
        x_cur: baseCurrency ? `${baseCurrency}`.toUpperCase() : null,
      };
      return _axios.default.get(`${this.url}/x-rates-by-pm`, {
        params: params,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  getRateByDate({
    period,
    fromDate,
    toDate,
    currency,
    baseCurrency,
    offerType = OFFER_TYPE.ALL,
    offerMarket = OFFER_MARKET.ALL,
    startDayAt7am = false,
  } = {}) {
    try {
      this.validateParams({
        period,
        fromDate,
        toDate,
        currency,
        baseCurrency,
        offerType,
        offerMarket,
        startDayAt7am,
      });
      const params = {
        period: period ? `${period}`.toUpperCase() : null,
        date_from: fromDate
          ? (0, _moment.default)(fromDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD hh:mm:ss"
            )
          : fromDate,
        date_to: toDate
          ? (0, _moment.default)(toDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD hh:mm:ss"
            )
          : toDate,
        cur: currency ? `${currency}`.toUpperCase() : null,
        x_cur: baseCurrency ? `${baseCurrency}`.toUpperCase() : null,
        offer: offerType,
        is_extern_offer: offerMarket,
        trmi: startDayAt7am,
      };
      return _axios.default.get(`${this.url}/x-rates-by-date-range-history`, {
        params: params,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  getHistorical({ currency, baseCurrency, startDayAt7am = false } = {}) {
    try {
      this.validateParams({
        currency,
        baseCurrency,
        startDayAt7am,
      });
      const params = {
        cur: currency ? `${currency}`.toUpperCase() : null,
        x_cur: baseCurrency ? `${baseCurrency}`.toUpperCase() : null,
        trmi: startDayAt7am,
      };
      return _axios.default.get(
        `${this.url}/x-rates-all-days-stats-by-currency`,
        {
          params: params,
        }
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }

  getMessagesCount({ fromDate, toDate } = {}) {
    try {
      this.validateParams({
        fromDate,
        toDate,
      });
      const params = {
        date_from: fromDate
          ? (0, _moment.default)(fromDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD hh:mm:ss"
            )
          : fromDate,
        date_to: toDate
          ? (0, _moment.default)(toDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD hh:mm:ss"
            )
          : toDate,
      };
      return _axios.default.get(`${this.url}/message-count-by-date-and-group`, {
        params: params,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  getOfficialRateByBanks({ ofDate } = {}) {
    try {
      this.validateParams({
        ofDate,
      });
      const params = {
        date: (0, _moment.default)(ofDate, "YYYY-MM-DD").format(
          "YYYY-MM-DD  hh:mm:ss"
        ),
      };
      return _axios.default.get(`${this.url}/banks-x-rates`, {
        params: params,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

class ExchangeV1 extends Exchange {
  constructor() {
    super("v1");
  }
}

exports.ExchangeV1 = ExchangeV1;

class ExchangeV2 extends Exchange {
  constructor() {
    super("v2");
    this.graphInfo = this.emptyGraphInfo();
    this.currencies = null;
  }

  emptyGraphInfo() {
    return {
      minimumCountMessages: -1,
      fromDate: null,
      countDaysBefore: -1,
      graph: new Graph({}),
      existingOffers: {},
    };
  }

  async createGraph(currency, minimumCountMessages, fromDate, countDaysBefore) {
    this.graphInfo = this.emptyGraphInfo(); // save parameters of call to memoize previous call size there are so many api calls

    this.graphInfo.minimumCountMessages = minimumCountMessages;
    this.graphInfo.fromDate = (0, _moment.default)(
      fromDate,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DD");
    this.graphInfo.countDaysBefore = countDaysBefore; // obtain date relative to countDaysBefore

    const daysCount =
      countDaysBefore > 0 ? countDaysBefore : countDaysBefore * -1;
    let startDate = (0, _moment.default)(fromDate, "YYYY-MM-DD")
      .add(-daysCount, "d")
      .format("YYYY-MM-DD");
    const endDate = (0, _moment.default)(fromDate, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    ); // get all existing currencies getting rates from CUP currency

    if (!this.currencies) {
      let currencies = new Set();
      const rates = (
        await this.getRateByCurrency({
          baseCurrency: "CUP",
          showMessages: false,
        })
      ).data["statistics"];

      for (const cur of Object.keys(rates)) {
        cur.split(".").forEach((x) => currencies.add(x));

        const [_currency, baseCurrency] = cur.split(".");

        this.graphInfo.graph.addNode(_currency);
        this.graphInfo.existingOffers[_currency] = {};

        if (!this.graphInfo.existingOffers[baseCurrency]) {
          this.graphInfo.graph.addNode(baseCurrency);
          this.graphInfo.existingOffers[baseCurrency] = {};
        }
        if (rates[cur]["count_messages"] >= minimumCountMessages) {
          this.graphInfo.existingOffers[_currency][baseCurrency] =
            rates[cur]["median"];
          this.graphInfo.existingOffers[baseCurrency][_currency] =
            1 / rates[cur]["median"];
          this.graphInfo.graph.addEdge(
            _currency,
            baseCurrency,
            rates[cur]["median"]
          );
          this.graphInfo.graph.addEdge(
            baseCurrency,
            _currency,
            1 / rates[cur]["median"]
          );
        }
      }
      this.currencies = [...currencies];
    } // create graph vertices

    // startDate = (["USD", "MLC", "ECU"].indexOf(baseCurrency)> -1)?(0, _moment.default)(fromDate, 'YYYY-MM-DD').add(-1, 'd').format('YYYY-MM-DD'):(0, _moment.default)(fromDate, 'YYYY-MM-DD').add(-daysCount, 'd').format('YYYY-MM-DD');
    startDate = (0, _moment.default)(fromDate, "YYYY-MM-DD")
      .add(-daysCount, "d")
      .format("YYYY-MM-DD");
    console.log(`startDate: ${startDate}`);
    const baseCurrencyRates = (
      await this.getRateByCurrency({
        baseCurrency: "all",
        fromDate: startDate,
        toDate: endDate,
        showMessages: false,
      })
    ).data["statistics"];

    for (const key of Object.keys(baseCurrencyRates)) {
      const _currency = key.split(".")[0];
      const baseCurrency = key.split(".")[1];
      this.graphInfo.graph.addNode(_currency);

      if (_currency !== baseCurrency) {
        if (baseCurrencyRates[key]["count_messages"] >= minimumCountMessages) {
          if (!this.graphInfo.existingOffers[_currency]) {
            // this currency is discover for first time, add to graph
            this.currencies.push(_currency);
            this.graphInfo.existingOffers[_currency] = {};
          }

          if (
            ( ["USD", "ECU", "MLC", "CUP"].includes(_currency) &&
            ["USD", "ECU", "MLC"].includes(baseCurrency))
            ||
            (["USD", "ECU", "MLC"].includes(_currency) && baseCurrency == "CUP")
          ) {
            // calcular la tasa con respecto a los valores del día anterior
          } else {
            this.graphInfo.existingOffers[_currency][baseCurrency] =
              baseCurrencyRates[key]["median"];
            this.graphInfo.graph.addEdge(
              _currency,
              baseCurrency,
              baseCurrencyRates[key]["median"]
            );
            if (this.graphInfo.existingOffers[baseCurrency] && !this.graphInfo.existingOffers[baseCurrency][_currency]) {
              this.graphInfo.existingOffers[baseCurrency][_currency] =
                1 / baseCurrencyRates[key]["median"];
              this.graphInfo.graph.addEdge(
                baseCurrency,
                _currency,
                1 / baseCurrencyRates[key]["median"]
              );
            }
          }
        }
      }
    }

    // this.graphInfo.graph.printGraph();
  }

  async convert({
    currency,
    minimumCountMessages = 10,
    fromDate = (0, _moment.default)(),
    countDaysBefore = 15,
  }) {
    try {
      currency = `${currency}`.toUpperCase();
      this.validateParams({
        currency,
        fromDate,
        countDaysBefore,
      });
      fromDate = (0, _moment.default)(fromDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      ); // if is the fist time or parameters change, create graph

      if (
        this.graphInfo.minimumCountMessages !== minimumCountMessages ||
        this.graphInfo.fromDate !== fromDate ||
        this.graphInfo.countDaysBefore !== countDaysBefore
      ) {
        await this.createGraph(
          currency,
          minimumCountMessages,
          fromDate,
          countDaysBefore
        );
      }

      const conversion = {
        [currency]: {},
      };
      const fromCurrency = currency;

      for (const toCurrency of this.currencies) {
        if (fromCurrency !== toCurrency) {
          let value = null;
          let path = null;
          let directExchange = false; // if the api provide a direct exchange between both currency

          if (
            this.graphInfo.existingOffers[fromCurrency] &&
            this.graphInfo.existingOffers[fromCurrency][toCurrency]
          ) {
            value = this.graphInfo.existingOffers[fromCurrency][toCurrency];
            path = `${fromCurrency},${toCurrency}`;
            directExchange = true;
          } else {
            // there are not a direct exchange, find the maximum cost path if any
            try {
              const max = this.graphInfo.graph.maxPath(
                fromCurrency,
                toCurrency
              );
              value = max.value ? max.value : null;
              path = max.path;
            } catch (e) {
              console.log("err", `${e}`);
            }
          }

          conversion[fromCurrency][toCurrency] = {
            value: `${value}`,
            path: `${path}`,
            directExchange: directExchange,
          };
        }
      }

      if (!this.currencies.includes(currency)) {
        throw {
          error: `Currency "${currency}" not found in API`,
        };
      }

      return conversion;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

exports.ExchangeV2 = ExchangeV2;

class Graph {
  constructor({ defaultInitDistance = 0 }) {
    this.nodes = [];
    this.edges = {};
    this.d = {}; // tasa de cambio de 1 unidad de una moneda al resto
    this.TT = {};
    this.defaultInitDistance = defaultInitDistance;
  }

  // printGraph () {
  //   console.log('graph');
  //   console.log(this.nodes);
  //   console.log(this.edges);
  // }
  addNode(v) {
    if (!this.nodes.includes(v)) {
      this.nodes.push(v);
    }
  }

  addEdge(v, w, c) {
    if (!Object.keys(this.edges).includes(v)) {
      this.edges[v] = {};
    }

    this.edges[v][w] = c;
  }

  initDistances() {
    for (const v of this.nodes) {
      this.d[v] = this.defaultInitDistance;
      this.TT[v] = null;
    }
  }

  getPath(from, to) {
    const path = [to];
    let current = to;
    let nodes = [];

    while (current !== from) {
      const parent = this.TT[current];
      path.push(parent);
      if (nodes.includes(current)) break;
      nodes.push(current);
      current = parent;
    }

    return path.reverse();
  }

  bfs(from, to) {
    if (!this.nodes.includes(from)) {
      throw new Error(`Graph don't have ${from} vertex`);
    }

    this.initDistances();
    const Q = [from];
    const visitedNodes = [];
    const visitedEdges = [];

    while (Q.length !== 0) {
      const u = Q.splice(0, 1)[0];
      const adj = this.edges[u] ? Object.keys(this.edges[u]) : [];

      for (const v of adj) {
        if (v === from) {
          continue;
        }

        if (!visitedEdges.includes(`${u}.${v}`)) {
          const dist =
            this.d[u] === this.defaultInitDistance
              ? this.edges[u][v]
              : this.d[u] * this.edges[u][v];

          if (this.d[v] < dist) {
            this.d[v] = dist;
            this.TT[v] = u;
          }

          visitedEdges.push(`${u}.${v}`);
        }

        if (v === to) {
          return
        }

        if (!visitedNodes.includes(v)) {
          Q.push(v);
        }
      }

      visitedNodes.push(u);
    }
  }

  maxPath(from, to) {
    this.bfs(from, to);
    return {
      value: this.d[to] ? this.d[to] : null,
      path: `${this.getPath(from, to)}`,
    };
  }
}
