<template>
  <div class="platformsview" style="margin: 50px">
    <div v-if="isLoading" style="font-size: 2em; padding: 10px">Cargando...</div>
    Cantidad de días <select v-model="selectedDays">
    <option value="1">1</option>
    <option value="7">7</option>
    <option value="15">15</option>
    <option value="30">30</option>
  </select>
    <table style="border: 1px solid 000" v-for="(value, key) in xrates" :key="key">
      <thead>
      <tr>
        <th :colspan="Object.keys(value[1]).length"><span style="font-size: 1.5em">{{ value[0] }}</span></th>
      </tr>
      <tr>
        <!-- Imprimir el nombre de la propiedad (la moneda) -->
        <!-- Iterar sobre las propiedades de la moneda -->
        <th v-for="(subValue, subKey) in value[1]" :key="subKey">
          <!-- Imprimir el nombre de la sub-moneda -->
          {{ subKey }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <!-- Espacio reservado para el nombre de la moneda -->

        <!-- Iterar sobre las propiedades de la moneda -->
        <td v-for="(subValue, subKey) in value[1]" :key="subKey">
          <!-- Imprimir la mediana de la sub-moneda -->
          ${{subValue.median.toString().padStart(2, "0")}} <br/> {{ subValue.count_values }} offers
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import api from "@/axios/index";

export default {
  name: "Home",
  mounted() {
    const me = this;
    me.fetchAndSaveTRMI();
  },
  data() {
    const actualDate = new Date();
    const trmiStablishedDate = new Date(new Date().setHours(7, 0, 0, 0));
    const initialDate = new Date(
      new Date().setDate(trmiStablishedDate.getDate() - 7)
    );
    // Calculate the new date_from for cpue_params which is 7 days before date_to
    const cpueDateFrom = new Date(trmiStablishedDate);
    cpueDateFrom.setDate(cpueDateFrom.getDate() - 5);
    const fecha_vigente = actualDate;
    if (actualDate.getHours() < 7) {
      trmiStablishedDate.setDate(trmiStablishedDate.getDate() - 1);
      initialDate.setDate(trmiStablishedDate.getDate() - 1);
    }
    return {
      isLoading: false,
      selectedDays: 7,
      fecha_establecida: trmiStablishedDate.toLocaleDateString(),
      fecha_vigente: fecha_vigente.toLocaleDateString(),
      trmi_params: {
        is_extern_offer: "Sí",
        msg: "false",
        date_from: `${initialDate.getFullYear()}-${
          initialDate.getMonth() + 1
        }-${initialDate.getDate()} 07:00:00`,
        date_to: `${trmiStablishedDate.getFullYear()}-${
          trmiStablishedDate.getMonth() + 1
        }-${trmiStablishedDate.getDate()} 07:00:00`,
        token: "hRaCcY78gXC3k9WRv01pR7V1fgSxlg"
      },
      cpue_params: {
        date_from: `${cpueDateFrom.getFullYear()}-${
          cpueDateFrom.getMonth() + 1
        }-${cpueDateFrom.getDate()} 07:00:00`,
        date_to: `${trmiStablishedDate.getFullYear()}-${
          trmiStablishedDate.getMonth() + 1
        }-${trmiStablishedDate.getDate()} 07:00:00`
      },
      hora: "7:00 AM",
      image_url: "pass",
      entries: { "USD": "- MLC", "ECU": "- MLC", "MLC": "-", "CUP": "-" },
      xrates: [],
      havanaDate: "",
      havanaTime: {
        hour: 0,
        minutes: 0,
        seconds: 0
      }
    };
  },
  watch: {
    selectedDays(newVal) {
      this.updateInitialDate(parseInt(newVal, 10));
      this.fetchAndSaveTRMI(); // Vuelve a hacer la petición
    }
  },
  computed: {
    havanaDateTime() {
      const date = new Date(
        `${this.havanaDate}T${this.formatNumber(
          this.havanaTime.hour
        )}:${this.formatNumber(this.havanaTime.minutes)}:${this.formatNumber(
          this.havanaTime.seconds
        )}`
      );
      console.log(
        "Havana Date Time",
        `${this.havanaDate}T${this.havanaTime.hour}:${this.havanaTime.minutes}:${this.havanaTime.seconds}`
      );
      console.log(date);
      return date;
    },
    havanaDateTimeString() {
      return `${this.havanaDateTime?.toLocaleString() ?? ""}`;
    },
    isCurrentDatePassSevenAm() {
      return !(
        this.havanaDateTime?.getTime() <
        new Date(this.fecha_establecida).getTime()
      );
    },
    messagesCount() {
      try {
        return (
          this.xrates[0][1].count_messages +
          this.xrates[1][1].count_messages +
          this.xrates[2][1].count_messages
        );
      } catch (e) {
        return "";
      }
    }
  },
  methods: {
    updateInitialDate(days) {
      const trmiStablishedDate = new Date(new Date().setHours(7, 0, 0, 0));
      const initialDate = new Date(new Date().setDate(trmiStablishedDate.getDate() - days));
      this.trmi_params.date_from = `${initialDate.getFullYear()}-${initialDate.getMonth() + 1}-${initialDate.getDate()} 07:00:00`;
    },
    // utils
    formatNumber(n) {
      return n.toString().padStart(2, "0");
    },
    // fetching data
    async fetchAndSaveTRMI() {
      const me = this;
      me.isLoading = true; // Inicia la carga
      return api
        .get("x-rates-by-pm", { params: me.trmi_params })
        .then(async (res) => {
          let data = res.data;
          let statistics = Object.entries(data.statistics);
          // statistics = statistics.filter((e) => me.isMLCEurOrUsd(e[0]));
          // statistics = statistics.sort((r1, r2) => {
          //   return me.curCompare(r1[0], r2[0]);
          // });
          me.xrates = statistics;
          me.havanaDate = data.date;
          me.havanaTime = {
            hour: data.hour,
            minutes: data.minutes,
            seconds: data.seconds
          };

        }).finally(() => {
          me.isLoading = false; // Termina la carga
        });
    },
    getCurrencyStyle(currency) {
      let currencyStyle = currency;
      if (currencyStyle == "USDT_TRC20") {
        currencyStyle = "USDT";
      }
      if (currencyStyle == "ECU") {
        currencyStyle = "EUR";
      }
      return currencyStyle;
    },
    // utils
    isMLCEurOrUsd(curShort) {
      return ["ECU", "MLC", "USD"].includes(curShort);
    }
  }
};
</script>

<style src="./styles.css"></style>

<style>

/* Estilo General de la Tabla */
table {
  width: 40%;
  border-collapse: collapse;
  margin: 20px 0;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f8f8f8;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

/* Cabecera de la Tabla */
th {
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 10px 15px;
  font-weight: normal;
}

/* Celdas de la Tabla */
td {
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

/* Última fila sin borde inferior */
tr:last-child td {
  border-bottom: none;
}

/* Estilo para las filas */
tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #e9e9e9;
}

/* Estilo para el texto de la cabecera */
.title-chart {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

/* Estilo para el select */
select {
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}
</style>
