<template>
  <div class="flex flex-row items-center">
    <p class="roboto-medium dark-color">{{ short }}</p>
    <p
      class="roboto-medium dark-color"
      style="font-size: 14px; margin-left: 3px; margin-right: 2px"
    >
      (
    </p>
    <div
      class="tron-cur-name"
      style="height: 16px; width: 40px; color: black"
    ></div>
    <p
      class="roboto-medium dark-color"
      style="font-size: 14px; margin-left: 2px"
    >
      )
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "TronName",
  props: ["short"],
};
</script>

<style scoped>
.tron-cur-name {
  background-image: url(../assets/tron.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
