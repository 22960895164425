<template>
  <div class="home">
    <div class="save-buttons-container">
      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="saveImage($refs.table_desktop, 2.9)"
      >
        Guardar POST
      </button>

      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="
          saveImageDirect('https://wa.cambiocuba.money/media_informal.png')
        "
      >
        Guardar Buy/Sell
      </button>

      <router-link class="ml-4 underline" to="/crypto-rates"
        >Ir a las criptomonedas</router-link
      >

      <p class="mt-3">
        Fecha y hora en la Habana en el servidor:
        <span
          v-if="showHavanaDate()"
          :class="{ 'red-text': !isCurrentDatePassSevenAm }"
          >{{ havanaDateTimeString }}</span
        >
      </p>
      <p>
        <span
          v-if="showHavanaDate() && !isCurrentDatePassSevenAm"
          class="red-text"
          >Hora no recomendada para establecer la tasa correspondiente a
          {{ establishedDate }}</span
        >
      </p>
    </div>

    <div class="first-images-row w-full flex gap-1">
      <section
        class="flex flex-col gap-8 table-desktop table-section w-full max-w-7xl"
        ref="table_desktop"
      >
        <header
          class="
            table-section-header
            w-full
            flex
            items-center
            justify-between
            py-12
            px-16
          "
        >
          <p class="font-bold roboto-slab text-white">
            TASAS DE CAMBIO DEL MERCADO INFORMAL
          </p>
          <img class="w-20 h-14" src="@/assets/logo.png" alt="ElToque" />
        </header>

        <div class="mx-8">
          <table class="w-full text-xl text-left m-0 shadow-none rounded-none">
            <thead class="table-head">
              <tr>
                <th scope="col" class="p-6"></th>
                <th
                  v-for="sourceKey in Object.keys(exchangeRates)"
                  :key="sourceKey"
                >
                  <span>{{ getSourceName(sourceKey) }}</span>
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr v-for="coin in coins" :key="coin">
                <th scope="row" class="p-6 uppercase">
                  <div
                    class="flex items-center justify-center gap-2 text-center"
                  >
                    <span>1 {{ coin }}</span>
                    <span
                      style="width: 40px; height: 40px"
                      :class="[
                        'flag-icon',
                        `flag-icon-${getCurrencyFlagCode(coin)}`,
                      ]"
                    ></span>
                  </div>
                </th>
                <td v-for="(data, coinId) in exchangeRates" :key="coinId">
                  <div
                    :class="[
                      {
                        'negative-diff': diffNegative(coin, data),
                        'positive-diff': diffPositive(coin, data),
                        'zero-diff': !diffNegative && !diffNegative,
                      },
                      'flex',
                      'items-center',
                      'justify-center',
                      'gap',
                    ]"
                  >
                    <span>{{ getKeyValue(coin, data) }}</span>
                    <div
                      v-if="hasKeyValue(coin, data)"
                      class="flex items-center justify-center w-fit h-fit"
                    >
                      <span
                        :class="[
                          {
                            'down-icon w-5 h-3': diffNegative(coin, data),
                            'up-icon w-5 h-3': diffPositive(coin, data),
                          },
                        ]"
                      >
                      </span>
                      <span
                        v-if="
                          diffPositive(coin, data) || diffNegative(coin, data)
                        "
                        class="text-xs mb-3"
                      >
                        {{ getDiff(coin, data) }}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <footer class="flex flex-col items-center justify-center gap-8 mb-8">
          <div class="roboto-bold italic">
            <p>
              *Los valores corresponden a aquellos que mostraban las distintas
              plataformas a las 7:00 a.m del día de hoy.
            </p>
            <!-- <p>
              *En el caso de Devtech, el valor corresponde a la última
              actualización para La provincia de La Habana.
            </p> -->
          </div>
          <div class="italic text-xl uppercase">
            <span>Fecha: </span>
            <span class="text-bold">{{ currentDate }}</span>
          </div>
          <div class="roboto-slab text-bold">
            <span class="social-box">@eltoquecom</span>
            <span class="social-box">eltoque.com</span>
          </div>
        </footer>
      </section>
    </div>
    <div class="mt-20">
      <img id="img-tasa-desktop" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from "@/axios/apiv1";
import apiEltoque from "@/axios/index";
import domtoimage from "dom-to-image-font-patch";
import FileSaver from "file-saver";

export default {
  name: "NewTablesView",
  mounted() {
    const me = this;
    me.fetchAndSaveTRMI().then(() => {
      me.generateTRMIQualityImages();
    });
  },
  data() {
    const actualDate = new Date();
    const trmiEstablishedDate = new Date(new Date().setHours(7, 0, 0, 0));
    const initialDate = new Date(
      new Date().setDate(trmiEstablishedDate.getDate() - 1)
    );
    // Calculate the new date_from for cpue_params which is 7 days before date_to
    const currentDate = actualDate;
    if (actualDate.getHours() < 7) {
      trmiEstablishedDate.setDate(trmiEstablishedDate.getDate() - 1);
      initialDate.setDate(trmiEstablishedDate.getDate() - 1);
    }
    return {
      establishedDate: trmiEstablishedDate.toLocaleDateString(),
      currentDate: currentDate.toLocaleDateString(),
      trmi_params: {
        msg: "false",
        date_from: `${initialDate.getFullYear()}-${
          initialDate.getMonth() + 1
        }-${initialDate.getDate()} 07:00:00`,
        date_to: `${trmiEstablishedDate.getFullYear()}-${
          trmiEstablishedDate.getMonth() + 1
        }-${trmiEstablishedDate.getDate()} 07:00:00`,
        token: "aCY78gC3kWRv1pR7VfgSlg",
      },
      hora: "7:00 AM",
      image_url: "pass",
      exchangeRates: {},
      orderedSources: [
        "ELTOQUE",
        "MDIV",
        "DEVTECH",
        "CAMBIO_CUP",
        "DIVISAS_CU",
      ],
      coins: ["EUR", "USD", "MLC"],
      havanaDate: "",
      havanaTime: {
        hour: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  computed: {
    havanaDateTime() {
      const date = new Date(
        `${this.havanaDate}T${this.formatNumber(
          this.havanaTime.hour
        )}:${this.formatNumber(this.havanaTime.minutes)}:${this.formatNumber(
          this.havanaTime.seconds
        )}`
      );
      return date;
    },
    havanaDateTimeString() {
      return `${this.havanaDateTime?.toLocaleString() ?? ""}`;
    },
    isCurrentDatePassSevenAm() {
      return !(
        this.havanaDateTime?.getTime() <
        new Date(this.establishedDate).getTime()
      );
    },
  },
  methods: {
    // utils
    formatNumber(n) {
      return n.toString().padStart(2, "0");
    },
    showHavanaDate() {
      return this.havanaDate !== "";
    },
    // fetching data
    async fetchAndSaveTRMI() {
      const me = this;

      await Promise.all([
        api.get("x-rates-external").then(async (res) => {
          let data = res.data;

          const groupedBySource = data.reduce((acc, item) => {
            const source = item.source;
            acc[source] = acc[source] || {};
            acc[source][item.coin] = {
              median_diff: item.diff,
              median: item.exchange_rate,
            };
            return acc;
          }, {});

          me.exchangeRates = { ...me.exchangeRates, ...groupedBySource };
        }),
        apiEltoque
          .get("x-rates", { params: me.trmi_params })
          .then(async (res) => {
            const { data } = res;
            const statistics = Object.entries(data.statistics)
              .filter((e) => me.allowedCoin(e[0]))
              .reduce((acc, [currency, data]) => {
                acc[currency === "ECU" ? "EUR" : currency] = {
                  median_diff: data.median_diff,
                  median: data.median,
                };
                return acc;
              }, {});
            me.exchangeRates["ELTOQUE"] = statistics;
            me.havanaDate = data.date;
            me.havanaTime = {
              hour: data.hour,
              minutes: data.minutes,
              seconds: data.seconds,
            };
          }),
      ]);

      me.exchangeRates = me.orderedSources.reduce((acc, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (me.exchangeRates.hasOwnProperty(key)) {
          const item = me.exchangeRates[key];
          if (Object.keys(item).length > 0) {
            acc[key] = item;
          }
        }
        return acc;
      }, {});
    },
    generateTRMIQualityImages() {
      this.saveImage(this.$refs.table_desktop, 2.9, true, "img-tasa-desktop");
    },
    saveImageDirect(url) {
      FileSaver.saveAs(url, "mediana_compra_venta.png");
    },
    allowedCoin(curShort) {
      return ["ECU", "MLC", "USD", "EUR"].includes(curShort);
    },
    saveImage(table, scale, show, id) {
      domtoimage
        .toBlob(table, {
          quality: 1,
          height: table.clientHeight * scale,
          width: table.clientWidth * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
          },
        })
        .then(function (blob) {
          if (show) {
            const objectURL = URL.createObjectURL(blob);
            const myImage = document.querySelector(`#${id}`);
            myImage.src = objectURL;
          } else {
            FileSaver.saveAs(blob, "TRMI.png");
          }
        });
    },
    getDiff(key, data) {
      const val =
        data[key] && data[key]["median_diff"]
          ? this.getValue(data[key]["median_diff"])
          : 0;

      return `${this.getSign(val)}${val}`;
    },
    getSign(val) {
      return val > 0 ? "+" : "";
    },
    diffNegative(key, data) {
      return data[key] && data[key]["median_diff"]
        ? data[key]["median_diff"] < 0
        : false;
    },
    diffPositive(key, data) {
      return data[key] && data[key]["median_diff"]
        ? data[key]["median_diff"] > 0
        : false;
    },
    getValue(value) {
      return parseFloat(value).toFixed(2);
    },
    hasKeyValue(key, data) {
      return data[key] ? true : false;
    },
    getKeyValue(key, data) {
      return data[key] ? `${this.getValue(data[key]["median"])} CUP` : "-";
    },
    getCurrencyFlagCode(coin) {
      switch (coin) {
        case "MLC":
          return "mlc";
        case "USD":
          return "us";
        case "EUR":
        case "ECU":
          return "eu";
        case "CUP":
          return "cu";
      }
    },
    getSourceName(sourceId) {
      switch (sourceId) {
        case "CAMBIO_CUP":
          return "Cambio CUP";
        case "DEVTECH":
          return "Devtech";
        case "MDIV":
          return "Mdiv.pro";
        case "ELTOQUE":
          return "elTOQUE";
        case "DIVISAS_CU":
          return "Divisas.cu";
        default:
          return "";
      }
    },
  },
};
</script>

<style src="./styles.css"></style>

<style>
.table-section {
  background-color: #dbdbe0;
  color: #293662 !important;
}

.table-section-header {
  background-color: #293662;
  font-size: 27px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 1px;
  text-align: start;
}

table {
  background-color: transparent;
  font-weight: 600;
}

table tr {
  border-bottom: 2px solid white;
  text-transform: uppercase;
}

table th {
  font-size: 24px;
  font-weight: 700;
  text-transform: none;
}

table tr th:not(:last-child),
table tr td:not(:last-child) {
  border-right: 2px solid white;
}

table th,
table tr {
  background-color: transparent !important;
  color: #293662 !important;
  text-align: center;
}

.red-text {
  color: red;
}

.social-box {
  padding: 10px;
  border: 2px solid #244d82;
  color: #244d82;
}

.down-icon {
  background-image: url(../assets/down-arrow.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.up-icon {
  background-image: url(../assets/up-arrow.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.flag-icon-mlc {
  background-image: url(../assets/card.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.negative-diff {
  color: #05af72;
}

.positive-diff {
  color: #d0011a;
}
</style>
