<template>
  <div class="flex flex-col items-start" style="font-size: 11px">
    <p class="roboto-mediumitalic legend-text" style="color: #18233f">
      ** Los valores equivalen a 1 unidad de la criptomoneda
    </p>
    <p class="roboto-mediumitalic legend-text" style="color: #18233f">
      <span class="text-transparent">*</span>* Los valores equivalen a 1 USD de la criptomoneda
    </p>
  </div>
</template>

<script>
export default {
  name: "CryptoLegend",
};
</script>

<style scoped>
.legend-text {
  @apply tracking-wide  self-start  text-left mr-1;
}
</style>
