<template>
  <div class="chart-container">
    <pulse-loader :loading="isLoading" :color="pulseColor"></pulse-loader>
    <slot></slot>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "ChartContainer",
  props: {
    isLoading: Boolean,
    pulseColor: String,
  },
  components: { PulseLoader },
};
</script>

<style scoped>
.chart-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
</style>
