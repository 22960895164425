<template>
  <div class="home">
    <div style="width: 95%; height: 400px; margin: 30px auto 0 auto">
      <div class="title-chart">
        {{ title }}
      </div>
        <value-type-radio-group
          class="flex flex-col items-start"
          v-model="showRealCryptoMarket"
        ></value-type-radio-group>
      <div>
        <select name="Moneda" v-model="currency" @change="onCurChange($event)">
          <option v-for="cur of currencies" :key="cur" :value="cur">
            {{ getLegend(cur) }}
          </option>
        </select>
      </div>
      <chart-container :is-loading="isLoading">
        <v-chart v-if="option && !isLoading" class="chart" :option="option">
        </v-chart>
      </chart-container>
      <date-range-selector-component
        v-if="!isLoading || !!option"
        :on-change="onDateRangeChange"
      ></date-range-selector-component>
    </div>
  </div>
</template>

<script>
import { getCurName } from "../utils/curUtils";
import { getLegend } from "../utils/curUtils";
import DateRangeSelectorComponent from "../components/DateRangeSelectorComponent";
import {
  getCryptoMarketByDateRange,
  getRatesByDateRange,
} from "../utils/apiUtils";
import { fillBlankSpaces, getDates } from "../utils/data";
import ChartContainer from "../components/ChartContainer";
import ValueTypeRadioGroup from "../components/ValueTypeRadioGroup";
import {trunc} from "../utils/numberUtils";

export default {
  name: "CryptoBuySellChartView",
  components: {
    DateRangeSelectorComponent,
    ChartContainer,
    ValueTypeRadioGroup,
  },
  mounted() {
    this.getOptions(this.days);
  },
  data() {
    return {
      isLoading: false,
      title: "Evolución de la tasa informal de compra/venta de",
      currencies: ["BTC", "USDT_TRC20", "BNB", "TRX"],
      currency: "BTC",
      days: 7,
      dateValue: [],
      option: null,
      showRealCryptoMarket: true,
      market_rates: [],
      dateList: [],
      sellBuyRates: [],
      tipos: ["Compra", "Venta"],
    };
  },
  watch: {
    showRealCryptoMarket() {
      this.option = this._getOptions();
    },
  },
  methods: {
    getCurName,
    getLegend,
    onCurChange() {
      if (this.days) {
        this.getOptions(this.days);
      } else {
        this.getOptions(0, this.dateValue);
      }
    },
    onDateRangeChange(days, dateValue) {
      console.log("on date range change", days, " ", dateValue);
      this.days = days;
      this.dateValue = dateValue ? dateValue : [];
      this.getOptions(days, dateValue);
    },

    _getOptions() {
      // console.log("make_rates", this.make_rates);

      let data = this.sellBuyRates.map((curData) => {
        // [sellArray, buyArray]

        // console.log("market info", curMarketInfo);
        // console.log(curData);
        return curData.map((current, idx) => {
          // console.log(this.make_rates?.[idx], idx);
          if (this.showRealCryptoMarket) {
            return current
              ? trunc(current * this.make_rates?.[idx]?.quote?.USD?.price) ?? 1
              : null;
          } else {
            return current;
          }
        });
      });

      return {
        grid: {
          bottom: 30,
        },
        color: [
          "#1636c1",
          "#d2011a",
          "#05ce7c",
          "#f99b2b",
          "#182360",
          "#1b5b20",
          "#6b05ce",
          "#cccc06",
        ],
        // Make gradient line here
        legend: {
          data: this.tipos,
          show: true,
          top: 10,
        },
        tooltip: {
          trigger: "axis",
          // formatter: '{b} | {c} CUP'
        },
        xAxis: [
          {
            data: this.dateList,
            axisLabel: {
              fontFamily: "Roboto-Medium",
            },
          },
          // {
          //   data: dateList,
          //   gridIndex: 1,
          // },
        ],
        yAxis: [
          {
            axisLabel: {
              fontFamily: "Roboto-Medium",
              formatter: function(value) {  // 3. Utilizar formato abreviado
                if (value >= 1000000) {
                  return (value / 1000000).toFixed(1) + 'M';
                }
                return value;
              }
            },
            max: function (value) {
              return value.max + 5;
            },
            min: function (value) {
              return value.min - 5;
            },
          },
          // {
          //   gridIndex: 1,
          // },
        ],

        series: data.map((el, i) => ({
          type: "line",
          showSymbol: true,
          smooth: false,
          name: this.tipos[i],
          data: el,
        })),
      };
    },

    getOptions(days, dates) {
      const me = this;
      me.isLoading = true;

      Promise.all([
        getCryptoMarketByDateRange(
          me.currency === "USDT_TRC20" ? "USDT" : me.currency,
          days,
          dates
        ),
        getRatesByDateRange(me.currency, days, dates, me.tipos[0]),
        getRatesByDateRange(me.currency, days, dates, me.tipos[1]),
      ])
        .then((res) => {
          // console.log("res", res);
          me.make_rates = res[0].data?.crypto_market_cap_historical;
          let data = res.slice(1, 3).map((resp) => {
            let v = resp.data;
            let len = v.length;
            return v.slice(1, len);
          });
          const dateList = getDates(data, days, dates);
          me.dateList = dateList;
          // console.log(dateList);

          data = data.map((items) => {
            const cleanArr = dateList.map((el) => ({ id: el, value: null }));
            for (const it of items) {
              const i = cleanArr.findIndex((el) => it._id === el.id);
              if (i > -1) cleanArr[i].value = it.median;
              else {
                // console.log("fecha no encontrada", it);
              }
              cleanArr[i].value = it.median;
            }
            return cleanArr.map((el) => el.value);
          });
          // Fill blank spaces
          data = data.map((ratesByDate) => fillBlankSpaces(ratesByDate));

          me.sellBuyRates = data;

          me.option = this._getOptions();
          me.isLoading = false;
        })
        .catch((e) => {
          me.isLoading = false;
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.toggle-container {
  margin-right: 7rem;
}
@media only screen and (max-width: 640px) {
  .toggle-container {
    margin-right: 0;
    justify-content: center;
  }
}
</style>
