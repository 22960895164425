<template>
  <hour-msg-line-chart :title="title" :currencies="currencies"></hour-msg-line-chart>
</template>

<script>
import HourMsgLineChart from "../components/HourMsgLineChart";

export default {
  name: "Chart",
  components: { HourMsgLineChart },
  data() {
    return {
      title: "Cantidad de mensajes por hora de cada moneda.",
      currencies: ["USD", "ECU", "MLC"],
    };
  },
};
</script>
