import api from "../axios";

export function getRatesByDateRange(
  cur,
  days,
  dates,
  offer,
  route = "x-rates-by-date-range-history"
) {
  // let route = "x-rates-by-date-range";
  let params = {
    trmi: true,
    cur: cur,
  };
  if (offer) {
    params["offer"] = offer;
  }
  if (dates && dates.length) {
    params["date_from"] = `${dates[0]} 00:00:00`;
    params["date_to"] = `${dates[1]} 23:59:59`;
  } else {
    params["period"] = `${days}D`;
  }
  return api.get(route, {
    params,
  });
}
export function getMsgHoursByDay(cur, days, dates) {
  const params = { cur };
  if (dates && dates.length) {
    params["date_from"] = `${dates[0]} 00:00:00`;
    params["date_to"] = `${dates[1]} 23:59:59`;
  } else {
    params["period"] = `${days}D`;
  }
  return api.get("median-by-hour", {
    params,
  });
}
export function getTRMIWithoutNewUsersByDay(date_from_nu, date_from, date_to) {
  // Route https://api.cambiocuba.money/api/v1/x-rates-wnu?&msg=false&date_from_nu=2024-01-01+08:00:00&date_from=2024-05-22+16:00:00&date_to=2024-05-23+16:00:00
  const params = {
    msg: false,
    date_from_nu,
    date_from,
    date_to,
  };
  return api.get("x-rates-wnu", {
    params,
  });
}
export function getTRMIByDay(date_from, date_to) {
  // Route https://api.cambiocuba.money/api/v1/x-rates-wnu?&msg=false&date_from_nu=2024-01-01+08:00:00&date_from=2024-05-22+16:00:00&date_to=2024-05-23+16:00:00
  const params = {
    msg: false,
    token: "aCY78gC3kWRv1pR7VfgSlg",
    date_from,
    date_to,
  };
  return api.get("x-rates", {
    params,
  });
}


export function getUserCountByDay(days, dates) {
  const params = {};
  if (dates && dates.length) {
    params["date_from"] = `${dates[0]} 00:00:00`;
    params["date_to"] = `${dates[1]} 23:59:59`;
  } else {
    params["period"] = `${days}D`;
  }
  return api.get("new-users-by-date", {
    params,
  });
}
export function getHourMedianPriceByDay(cur, day) {
  const params = { cur, day };
  return api.get("message-volume-by-hour", {
    params,
  });
}
export function getCryptoMarketByDateRange(cur, days, dates, offer) {
  return getRatesByDateRange(
    cur,
    days,
    dates,
    offer,
    "crypto-market-listings-historical"
  );
}
