<template>
  <div class="home">
    <div style="width: 95%; height: 400px; margin: 30px auto 0 auto">
      <div class="title-chart">
        {{ title }}
      </div>
        <value-type-radio-group
            class="flex flex-col items-start"
            v-model="showRealCryptoMarket"
        ></value-type-radio-group>
      <div>
        <select name="Moneda" v-model="currency" @change="onCurChange($event)">
          <option v-for="cur of currencies" :key="cur" :value="cur">
            {{ getLegend(cur) }}
          </option>
        </select>
      </div>
      <chart-container :is-loading="isLoading">
        <v-chart v-if="option && !isLoading" class="chart" :option="option">
        </v-chart>
      </chart-container>
    </div>
  </div>
</template>

<script>
import { getCurName, getLegend } from "../utils/curUtils";
import {
  getCryptoMarketByDateRange,
  getRatesByDateRange,
} from "../utils/apiUtils";
import ChartContainer from "../components/ChartContainer";
import ValueTypeRadioGroup from "../components/ValueTypeRadioGroup";
import {trunc} from "../utils/numberUtils";

export default {
  name: "CryptoCandlestickChartView",
  components: { ValueTypeRadioGroup, ChartContainer },
  watch: {
    showRealCryptoMarket() {
      this.option = this._getOptions();
    },
  },
  data() {
    return {
      isLoading: false,
      title: "Evolución de la tasa informal del",
      currencies: ["BTC", "USDT_TRC20", "BNB", "TRX"],
      days: 365,
      currency: "BTC",
      option: null,
      dateList: [],
      rates: [],
      clandestickVals: [],
      market_rates: [],
      showRealCryptoMarket: true,
    };
  },
  mounted() {
    this.getOptions(this.days);
  },

  methods: {
    getLegend,
    onCurChange() {
      this.getOptions(this.days);
    },
    _getOptions() {
      let data = this.clandestickVals.map((el, idx) => {
        // console.log(el.value)
        if (this.showRealCryptoMarket && el.value) {
          // console.log(el, this.market_rates?.[idx]?.quote);
          // console.log([
          //   this.market_rates?.[idx]?.quote?.USD?.price * el.value[0], // first
          //   this.market_rates?.[idx]?.quote?.USD?.price * el.value[1], // last
          //   this.market_rates?.[idx]?.quote?.USD?.price * el.value[2], // min
          //   this.market_rates?.[idx]?.quote?.USD?.price * el.value[3], // max
          // ])
          return [
            trunc(this.market_rates?.[idx]?.quote?.USD?.price * el.value[0]), // first
            trunc(this.market_rates?.[idx]?.quote?.USD?.price * el.value[1]), // last
            trunc(this.market_rates?.[idx]?.quote?.USD?.price * el.value[2]), // min
            trunc(this.market_rates?.[idx]?.quote?.USD?.price * el.value[3]), // max
          ];

          // return el.value
        } else {
          return el.value;
        }
      });

      // console.log("data", data);
      let bardata = this.rates.map((el, idx) => {
        // console.log(el);
        // console.log(this.market_rates?.[idx]?.quote?.USD?.price);
        return this.showRealCryptoMarket && el
          ? el * this.market_rates?.[idx]?.quote?.USD?.price
          : el;
      });

      // console.log(bardata)

      const upColor = "#ec0000";
      const downColor = "#00da3c";

      return {
        grid: [
          {
            height: "50%",
          },
          {
            top: "70%",
            height: "16%",
          },
        ],
        color: [
          "#1636c1",
          "#d2011a",
          "#05ce7c",
          "#f99b2b",
          "#182360",
          "#1b5b20",
          "#6b05ce",
          "#cccc06",
        ],
        // Make gradient line here
        legend: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          // formatter: '{b} | {c} CUP'
        },
        visualMap: {
          show: false,
          seriesIndex: 5,
          dimension: 2,
          pieces: [
            {
              value: 1,
              color: downColor,
            },
            {
              value: -1,
              color: upColor,
            },
          ],
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: false,
            },
          },
        },
        xAxis: [
          {
            type: "category",
            axisLabel: {
              fontFamily: "Roboto-Medium",
            },
            data: this.dateList,
            scale: true,
            boundaryGap: false,
            axisLine: { onZero: false },
            splitLine: { show: false },
            min: "dataMin",
            max: "dataMax",
            axisPointer: {
              z: 100,
            },
          },
          {
            type: "category",
            gridIndex: 1,
            data: this.dateList,
            scale: true,
            boundaryGap: false,
            axisLine: { onZero: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { show: false },
            splitNumber: 20,
            min: "dataMin",
            max: "dataMax",
          },
        ],
        yAxis: [
          {
            min: 30,
            axisLabel: {
              fontFamily: "Roboto-Medium",
            },
            scale: true,
            splitArea: {
              show: true,
            },
          },
          {
            scale: true,
            axisLabel: {
              show: false,
            },
            gridIndex: 1,
            splitNumber: 2,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: [0, 1],
            start: 60,
            end: 100,
          },
          {
            show: true,
            xAxisIndex: [0, 1],
            type: "slider",
            top: "85%",
            start: 98,
            end: 100,
          },
        ],
        series: [
          {
            type: "candlestick",
            smooth: false,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: null,
              borderColor0: null,
            },
            name: getCurName(this.currency),
            data: data,
          },
          {
            name: "Mediana",
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: bardata,
          },
        ],
      };
    },
    getOptions(days) {
      const me = this;
      me.days = days;
      me.isLoading = true;
      Promise.all([
        getRatesByDateRange(me.currency, me.days),
        getCryptoMarketByDateRange(
          me.currency === "USDT_TRC20" ? "USDT" : me.currency,
          days
        ),
      ])
        .then((res) => {
          let data = res[0].data;
          this.market_rates = res[1].data.crypto_market_cap_historical;
          const dateList = data.map((d) => d._id);
          const bardata = [];

          const cleanArr = dateList.map((el) => ({ id: el, value: null }));
          for (const it of data) {
            const i = cleanArr.findIndex((el) => it._id === el.id);
            if (i > -1) {
              cleanArr[i].value = [
                it.first.value,
                it.last.value,
                it.min,
                it.max,
              ];
              bardata[i] = it.median;
            }
          }

          // console.log(bardata);
          // data = cleanArr.map((el) => el.value);
          this.clandestickVals = cleanArr;
          this.rates = bardata;
          // const valueList = data.map((d) => d.median);
          // const upColor = "#ec0000";
          // const downColor = "#00da3c";
          me.dateList = dateList;
          me.option = this._getOptions();
          me.isLoading = false;
        })
        .catch((e) => {
          me.isLoading = false;
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.toggle-container {
  margin-right: 7rem;
}
@media only screen and (max-width: 640px) {
  .toggle-container {
    margin-right: 0;
    justify-content: center;
  }
}
</style>
