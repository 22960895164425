<template>
  <div class="home">
    <div style="width: 95%; height: 400px; margin: 30px auto 0 auto">
      <div class="title-chart">
        {{ title }}
      </div>
      <div>
        <select name="Moneda" v-model="currency" @change="onCurChange($event)">
          <option v-for="cur of currencies" :key="cur" :value="cur">
            {{ getLegend(cur) }}
          </option>
        </select>
      </div>
      <chart-container :is-loading="isLoading">
        <v-chart v-if="option && !isLoading" class="chart" :option="option">
        </v-chart>
      </chart-container>
    </div>
  </div>
</template>

<script>
import { getCurName, getLegend } from "../utils/curUtils";
import { getRatesByDateRange } from "../utils/apiUtils";
import ChartContainer from "../components/ChartContainer";

export default {
  name: "CurClandestikChart",
  components: { ChartContainer },
  props: {
    title: String,
    currencies: Array,
    initialSelectedCur: String,
  },
  data() {
    return {
      isLoading: false,
      days: 365,
      currency: this.initialSelectedCur,
      option: null,
    };
  },
  mounted() {
    this.getOptions(this.days);
  },

  methods: {
    getLegend,
    onCurChange() {
      this.getOptions(this.days);
    },
    getOptions(days) {
      const me = this;
      me.days = days;

      getRatesByDateRange(me.currency, me.days)
        .then((res) => {
          let data = res.data;
          const dateList = data.map((d) => d._id);
          const bardata = [];

          const cleanArr = dateList.map((el) => ({ id: el, value: null }));
          for (const it of data) {
            const i = cleanArr.findIndex((el) => it._id === el.id);
            if (i > -1) {
              cleanArr[i].value = [
                it.first.value,
                it.last.value,
                it.min,
                it.max,
              ];
              bardata[i] = it.median;
            }
          }
          data = cleanArr.map((el) => {
            // console.log(el.value)
            return el.value
          });
          // const valueList = data.map((d) => d.median);

          const upColor = "#ec0000";
          const downColor = "#00da3c";
          me.option = {
            grid: [
              {
                height: "50%",
              },
              {
                top: "70%",
                height: "16%",
              },
            ],
            color: [
              "#1636c1",
              "#d2011a",
              "#05ce7c",
              "#f99b2b",
              "#182360",
              "#1b5b20",
              "#6b05ce",
              "#cccc06",
            ],
            // Make gradient line here
            legend: {
              show: false,
            },
            tooltip: {
              trigger: "axis",
              // formatter: '{b} | {c} CUP'
            },
            visualMap: {
              show: false,
              seriesIndex: 5,
              dimension: 2,
              pieces: [
                {
                  value: 1,
                  color: downColor,
                },
                {
                  value: -1,
                  color: upColor,
                },
              ],
            },
            toolbox: {
              feature: {
                dataZoom: {
                  yAxisIndex: false,
                },
              },
            },
            xAxis: [
              {
                type: "category",
                axisLabel: {
                  fontFamily: "Roboto-Medium",
                },
                data: dateList,
                scale: true,
                boundaryGap: false,
                axisLine: { onZero: false },
                splitLine: { show: false },
                min: "dataMin",
                max: "dataMax",
                axisPointer: {
                  z: 100,
                },
              },
              {
                type: "category",
                gridIndex: 1,
                data: dateList,
                scale: true,
                boundaryGap: false,
                axisLine: { onZero: false },
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: { show: false },
                splitNumber: 20,
                min: "dataMin",
                max: "dataMax",
              },
            ],
            yAxis: [
              {
                min: 30,
                axisLabel: {
                  fontFamily: "Roboto-Medium",
                },
                scale: true,
                splitArea: {
                  show: true,
                },
              },
              {
                scale: true,
                axisLabel: {
                  show: false,
                },
                gridIndex: 1,
                splitNumber: 2,
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
              },
            ],
            dataZoom: [
              {
                type: "inside",
                xAxisIndex: [0, 1],
                start: 60,
                end: 100,
              },
              {
                show: true,
                xAxisIndex: [0, 1],
                type: "slider",
                top: "85%",
                start: 98,
                end: 100,
              },
            ],
            series: [
              {
                type: "candlestick",
                smooth: false,
                itemStyle: {
                  color: upColor,
                  color0: downColor,
                  borderColor: null,
                  borderColor0: null,
                },
                name: getCurName(me.currency),
                data: data,
              },
              {
                name: "Mediana",
                type: "bar",
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: bardata,
              },
            ],
          };
          me.isLoading = false;
        })
        .catch((e) => {
          me.isLoading = false;
          console.log(e);
        });
    },
  },
};
</script>

<style scoped></style>
