<template>
  <div>
    <div class="save-buttons-container pt-8">
      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="saveImage($refs.rates_crypto_table, 2.9)"
      >
        Guardar POST
      </button>
      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="saveImage($refs.rates_crypto_table_story, 3)"
      >
        Guardar Storie
      </button>
      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="saveImage($refs.rates_crypto_table_box, 3)"
      >
        Guardar BOX
      </button>
    </div>
    <div
      class="crypto-images-row flex gap-1"
      style="margin-top: 50px; margin-bottom: 200px"
    >
      <div
        ref="rates_crypto_table_story"
        class="portrait-image flex-none portrait-table-container"
        style="height: 540px"
      >
        <div
          class="box-rates-title-story text-center"
          style="background-color: var(--primary-red-color)"
        >
          <div class="logo-container my-0 mx-auto" style="width: 15%">
            <img class="w-60" src="@/assets/logo.png" alt="" />
          </div>
          <div
            class="title-container my-0 mx-auto"
            style="padding: 20px 0 30px 0"
          >
            <p class="box-title text-white roboto-medium text-upper mb-0">
              Tasa representativa del
            </p>
            <p class="box-title text-white roboto-medium text-upper mt-0">
              mercado de criptomonedas
            </p>
          </div>
        </div>
        <div class="p-5" style="background-color: #ececec; font-size: 14px">
          <div class="flex justify-center black-body-row">
            <div class="black-right-border roboto-bold w-1/2">
              <div class="flex items-center justify-start"></div>
            </div>
            <div
              :class="[
                'roboto-bold',
                'dark-color',
                'value-box',
                'zero-diff',
                'black-right-border',
                'w-1/4',
                'flex',
                'items-center',
                'justify-center',
                'box-border',
              ]"
              style="padding-top: 5px"
            >
              CUP
            </div>
            <div
              :class="[
                'roboto-bold',
                'dark-color',
                'value-box',
                'zero-diff',
                'w-1/4',
                'flex',
                'items-center',
                'justify-center',
                'box-border',
              ]"
              style="padding-top: 5px"
            >
              MLC
            </div>
          </div>
          <div
            class="values border-b-2"
            style="border-color: var(--text-color)"
          >
            <div
              :key="r[0]"
              v-for="(r, i) in cryptoXrates"
              :class="[
                { 'black-body-row': i < cryptoXrates.length - 1 },
                'flex',
                'justify-center',
              ]"
            >
              <div class="black-right-border roboto-medium dark-color w-1/2">
                <div class="flex items-center justify-start">
                  <div style="margin: 8px 8px 8px 8px">
                    <span
                      style="width: 28px; height: 28px"
                      :class="['flag-icon', `${getCryptoIconName(r)}-icon`]"
                    ></span>
                  </div>
                  <div>
                    <div v-if="isTron(r)">
                      <tron-name :short="getCryptoShortCode(r)">**</tron-name>
                    </div>
                    <div v-else>
                      <p class="m-0">
                        {{ getCryptoShortCode(r) }}<span>&nbsp;</span>({{
                          getCryptoName(r)
                        }}) *
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'roboto-medium',
                  'dark-color',
                  'value-box',
                  'black-right-border',
                  'w-1/4',
                  'flex',
                  'items-center',
                  'justify-start',
                  'pl-1',
                  'pr-1',
                  'box-border',
                  {
                    'crypto-negative-diff': cryptoDiffNegative(r, 1),
                    'crypto-positive-diff': cryptoDiffPositive(r, 1),
                  },
                ]"
              >
                {{ getCryptoValue(r, 1) }}
                <div
                  :class="[
                    {
                      'crypto-down-icon': cryptoDiffNegative(r, 1),
                      'crypto-up-icon': cryptoDiffPositive(r, 1),
                    },
                  ]"
                  style="
                    width: 9px;
                    height: 12px;
                    margin-left: 2px;
                    margin-bottom: 0;
                  "
                ></div>
                <span
                  v-if="cryptoDiffPositive(r, 1) || cryptoDiffNegative(r, 1)"
                  style="font-size: 9px; margin-bottom: 10px; margin-left: 2px"
                  >{{ getCryptoDiff(r, 1) }}</span
                >
              </div>
              <div
                :class="[
                  'roboto-medium',
                  'dark-color',
                  'value-box',
                  'zero-diff',
                  'w-1/4',
                  'flex',
                  'items-center',
                  'justify-start',
                  'pl-2',
                  'box-border',
                  {
                    'crypto-negative-diff': cryptoDiffNegative(r, 3),
                    'crypto-positive-diff': cryptoDiffPositive(r, 3),
                  },
                ]"
              >
                {{ getCryptoValue(r, 3) }}
                <div
                  :class="[
                    {
                      'crypto-down-icon': cryptoDiffNegative(r, 3),
                      'crypto-up-icon': cryptoDiffPositive(r, 3),
                    },
                  ]"
                  style="
                    width: 9px;
                    height: 12px;
                    margin-left: 2px;
                    margin-bottom: 0;
                  "
                ></div>
                <span
                  v-if="cryptoDiffNegative(r, 3) || cryptoDiffPositive(r, 3)"
                  style="font-size: 9px; margin-bottom: 10px; margin-left: 2px"
                  >{{ getCryptoDiff(r, 3) }}</span
                >
              </div>
            </div>
          </div>

          <div
            class="text-upper box-border border-b-2 py-2 pt-3"
            style="font-size: 9px; border-color: var(--text-color)"
          >
            <span class="roboto-mediumitalic regular-text-color"
              >establecida:
            </span>
            <span class="space-2 roboto-blackitalic regular-text-color">{{
              fecha_establecida
            }}</span>
            <span class="dot"> </span>
            <span class="space-2 roboto-blackitalic regular-text-color">{{
              hora
            }}</span>
            <span class="dot"> </span>
            <span class="space-2 roboto-blackitalic regular-text-color"
              >Cuba</span
            >
            <p class="roboto-mediumitalic regular-text-color">
              Vigente:
              <span class="roboto-blackitalic regular-text-color">{{
                fecha_vigente
              }}</span>
            </p>
          </div>
          <div style="margin-top: 10px; font-size: 11px">
            <div class="flex-1 pl-3">
              <crypto-legend></crypto-legend>
            </div>
            <p
              class="
                flex-1
                unbordered-social-box
                roboto-slab-bold
                mx-0
                ml-auto
                p-0
              "
            >
              @eltoquecom / eltoque.com
            </p>
          </div>
        </div>
      </div>

      <div
        ref="rates_crypto_table"
        class="flex-none block"
        style="width: 600px; height: 375px"
      >
        <div
          class="flex text-left justify-center items-center"
          style="
            background-color: var(--primary-red-color);
            padding: 15px 40px 18px 40px;
          "
        >
          <div
            class="logo-container flex flex-col justify-center content-end"
            style="width: 15%"
          >
            <img width="55" src="@/assets/logo.png" alt="" />
          </div>
          <div class="title-container" style="width: 65%; margin-top: 5px">
            <p class="box-title text-white roboto-medium text-upper mb-0">
              Tasa representativa del
            </p>
            <p class="box-title text-white roboto-medium text-upper mt-0">
              mercado de criptomonedas
            </p>
          </div>
        </div>
        <div
          class="box-rates-body"
          style="background-color: #ececec; font-size: 14px; padding-top: 0"
        >
          <div class="flex justify-end black-body-row">
            <div class="black-right-border roboto-bold w-1/2">
              <div class="flex items-center justify-start"></div>
            </div>
            <div
              :class="[
                'roboto-bold',
                'dark-color',
                'value-box',
                'zero-diff',
                'black-right-border',
                'w-1/5',
                'flex',
                'items-center',
                'justify-center',
                'box-border',
              ]"
              style="padding-top: 5px"
            >
              CUP
            </div>
            <div
              :class="[
                'roboto-bold',
                'dark-color',
                'value-box',
                'zero-diff',
                'w-1/5',
                'flex',
                'items-center',
                'justify-center',
                'box-border',
              ]"
              style="padding-top: 5px"
            >
              MLC
            </div>
          </div>
          <div
            class="values border-b-2"
            style="border-color: var(--text-color)"
          >
            <div
              :key="r[0]"
              v-for="(r, i) in cryptoXrates"
              :class="[
                { 'black-body-row': i < cryptoXrates.length - 1 },
                'flex',
                'justify-end',
              ]"
            >
              <div class="black-right-border roboto-medium dark-color w-1/2">
                <div class="flex items-center justify-start">
                  <div style="margin: 3px 15px 5px 0">
                    <span
                      style="width: 28px; height: 28px"
                      :class="['flag-icon', `${getCryptoIconName(r)}-icon`]"
                    ></span>
                  </div>
                  <div style="margin-right: 30px">
                    <div v-if="isTron(r)">
                      <tron-name :short="getCryptoShortCode(r)">**</tron-name>
                    </div>
                    <div v-else>
                      <p class="m-0">
                        {{ getCryptoShortCode(r) }}<span>&nbsp;</span>({{
                          getCryptoName(r)
                        }}) *
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'roboto-medium',
                  'dark-color',
                  'value-box',
                  'black-right-border',
                  'w-1/5',
                  'flex',
                  'items-center',
                  'justify-start',
                  'pl-2',
                  'box-border',
                  {
                    'crypto-negative-diff': cryptoDiffNegative(r, 1),
                    'crypto-positive-diff': cryptoDiffPositive(r, 1),
                  },
                ]"
              >
                {{ getCryptoValue(r, 1) }}
                <div
                  :class="[
                    {
                      'crypto-down-icon': cryptoDiffNegative(r, 1),
                      'crypto-up-icon': cryptoDiffPositive(r, 1),
                    },
                  ]"
                  style="
                    width: 9px;
                    height: 12px;
                    margin-left: 3px;
                    margin-bottom: 2px;
                  "
                ></div>
                <span
                  v-if="cryptoDiffPositive(r, 1) || cryptoDiffNegative(r, 1)"
                  style="font-size: 9px; margin-bottom: 10px; margin-left: 2px"
                  >{{ getCryptoDiff(r, 1) }}</span
                >
              </div>
              <div
                :class="[
                  'roboto-medium',
                  'dark-color',
                  'value-box',
                  'zero-diff',
                  'w-1/5',
                  'flex',
                  'items-center',
                  'justify-start',
                  'pl-2',
                  'box-border',
                  {
                    'crypto-negative-diff': cryptoDiffNegative(r, 3),
                    'crypto-positive-diff': cryptoDiffPositive(r, 3),
                  },
                ]"
              >
                {{ getCryptoValue(r, 3) }}
                <div
                  :class="[
                    {
                      'crypto-down-icon': cryptoDiffNegative(r, 3),
                      'crypto-up-icon': cryptoDiffPositive(r, 3),
                    },
                  ]"
                  style="
                    width: 9px;
                    height: 12px;
                    margin-left: 3px;
                    margin-bottom: 2px;
                  "
                ></div>
                <span
                  v-if="cryptoDiffNegative(r, 3) || cryptoDiffPositive(r, 3)"
                  style="font-size: 9px; margin-bottom: 10px; margin-left: 2px"
                  >{{ getCryptoDiff(r, 3) }}</span
                >
              </div>
            </div>
          </div>

          <div
            class="text-upper box-border border-b-2"
            style="
              padding-top: 8px;
              padding-bottom: 8px;
              font-size: 9px;
              border-color: var(--text-color);
            "
          >
            <span class="roboto-mediumitalic regular-text-color"
              >establecida:
            </span>
            <span class="roboto-blackitalic space-2 regular-text-color">{{
              fecha_establecida
            }}</span>
            <span class="dot"> </span>
            <span class="roboto-blackitalic space-2 regular-text-color">{{
              hora
            }}</span>
            <span class="dot"> </span>
            <span class="roboto-blackitalic space-2 regular-text-color"
              >Cuba</span
            >
            <span class="dot"> </span>
            <span class="roboto-mediumitalic regular-text-color"
              >Vigente:
            </span>
            <span class="roboto-blackitalic regular-text-color">{{
              fecha_vigente
            }}</span>
          </div>
          <div
            class="flex flex-row justify-around"
            style="margin-top: 10px; font-size: 11px"
          >
            <div>
              <crypto-legend></crypto-legend>
            </div>
            <p class="unbordered-social-box roboto-slab-bold mx-0 ml-auto">
              @eltoquecom / <br />
              eltoque.com
            </p>
          </div>
        </div>
      </div>

      <div
        ref="rates_crypto_table_box"
        class="squared-image flex-none block"
        style="width: 500px; height: 420px"
      >
        <div
          class="flex text-left justify-between"
          style="background-color: var(--primary-red-color); padding: 20px 60px"
        >
          <div
            class="logo-container flex flex-col justify-center"
            style="width: 15%"
          >
            <img width="70" src="@/assets/logo.png" alt="" />
          </div>
          <div class="title-container w-4/5" style="margin-top: 5px">
            <p class="box-title text-white roboto-medium text-upper mb-0">
              Tasa representativa del
            </p>
            <p class="box-title text-white roboto-medium text-upper mt-0">
              mercado de criptomonedas
            </p>
          </div>
        </div>
        <div
          class="box-rates-body"
          style="background-color: #ececec; font-size: 14px; padding-top: 0"
        >
          <div class="flex justify-center black-body-row">
            <div class="black-right-border roboto-bold w-1/2">
              <div class="flex items-center justify-center"></div>
            </div>
            <div
              :class="[
                'roboto-bold',
                'dark-color',
                'value-box',
                'zero-diff',
                'black-right-border',
                'w-1/4',
                'flex',
                'items-center',
                'justify-center',
                'box-border',
              ]"
              style="padding-top: 5px"
            >
              CUP
            </div>
            <div
              :class="[
                'roboto-bold',
                'dark-color',
                'value-box',
                'zero-diff',
                'w-1/4',
                'flex',
                'items-center',
                'justify-center',
                'box-border',
              ]"
              style="padding-top: 5px"
            >
              MLC
            </div>
          </div>
          <div
            class="values border-b-2"
            style="border-color: var(--text-color)"
          >
            <div
              :key="r[0]"
              v-for="(r, i) in cryptoXrates"
              :class="[
                { 'black-body-row': i < cryptoXrates.length - 1 },
                'flex',
                'justify-center',
              ]"
            >
              <div class="black-right-border roboto-medium dark-color w-1/2">
                <div class="flex items-center justify-start ml-4">
                  <div style="margin: 8px 10px 8px 0">
                    <span
                      style="width: 28px; height: 28px"
                      :class="['flag-icon', `${getCryptoIconName(r)}-icon`]"
                    ></span>
                  </div>
                  <div style="margin-right: 0">
                    <div v-if="isTron(r)">
                      <tron-name :short="getCryptoShortCode(r)">**</tron-name>
                    </div>
                    <div v-else>
                      <p class="m-0">
                        {{ getCryptoShortCode(r) }}<span>&nbsp;</span>({{
                          getCryptoName(r)
                        }}) *
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'roboto-medium',
                  'dark-color',
                  'value-box',
                  'black-right-border',
                  'w-1/4',
                  'flex',
                  'items-center',
                  'justify-start',
                  'pl-2',
                  'pr-2',
                  'box-border',
                  {
                    'crypto-negative-diff': cryptoDiffNegative(r, 1),
                    'crypto-positive-diff': cryptoDiffPositive(r, 1),
                  },
                ]"
              >
                {{ getCryptoValue(r, 1) }}
                <div
                  :class="[
                    {
                      'crypto-down-icon': cryptoDiffNegative(r, 1),
                      'crypto-up-icon': cryptoDiffPositive(r, 1),
                    },
                  ]"
                  style="
                    width: 9px;
                    height: 12px;
                    margin-left: 3px;
                    margin-bottom: 2px;
                  "
                ></div>
                <span
                  v-if="cryptoDiffPositive(r, 1) || cryptoDiffNegative(r, 1)"
                  style="font-size: 9px; margin-bottom: 10px; margin-left: 2px"
                  >{{ getCryptoDiff(r, 1) }}</span
                >
              </div>
              <div
                :class="[
                  'roboto-medium',
                  'dark-color',
                  'value-box',
                  'zero-diff',
                  'w-1/4',
                  'flex',
                  'items-center',
                  'justify-start',
                  'pl-2',
                  'pr-2',
                  'box-border',
                  {
                    'crypto-negative-diff': cryptoDiffNegative(r, 3),
                    'crypto-positive-diff': cryptoDiffPositive(r, 3),
                  },
                ]"
              >
                {{ getCryptoValue(r, 3) }}
                <div
                  :class="[
                    {
                      'crypto-down-icon': cryptoDiffNegative(r, 3),
                      'crypto-up-icon': cryptoDiffPositive(r, 3),
                    },
                  ]"
                  style="
                    width: 9px;
                    height: 12px;
                    margin-left: 3px;
                    margin-bottom: 2px;
                  "
                ></div>
                <span
                  v-if="cryptoDiffNegative(r, 3) || cryptoDiffPositive(r, 3)"
                  style="font-size: 9px; margin-bottom: 10px; margin-left: 2px"
                  >{{ getCryptoDiff(r, 3) }}</span
                >
              </div>
            </div>
          </div>

          <div
            class="text-upper box-border border-b-2 py-3"
            style="font-size: 10px; border-color: var(--text-color)"
          >
            <span class="roboto-mediumitalic regular-text-color"
              >establecida:
            </span>
            <span class="roboto-blackitalic space-2 regular-text-color">{{
              fecha_establecida
            }}</span>
            <span class="dot space-2"> </span>
            <span class="roboto-blackitalic space-2 regular-text-color">{{
              hora
            }}</span>
            <span class="dot space-2"> </span>
            <span class="roboto-blackitalic space-2 regular-text-color"
              >Cuba</span
            >
            <span class="dot space-2"> </span>
            <span class="regular-text-color">Vigente: </span>
            <span class="roboto-blackitalic regular-text-color">{{
              fecha_vigente
            }}</span>
          </div>
          <div style="margin-top: 10px; font-size: 11px">
            <div class="flex flex-row justify-center">
              <crypto-legend></crypto-legend>
            </div>
            <p
              class="
                flex-1
                unbordered-social-box
                roboto-slab-bold
                mx-0
                ml-auto
                p-0
              "
            >
              @eltoquecom / eltoque.com
            </p>
          </div>
        </div>
      </div>
    </div>
    <img id="crypto_imgtasa" />
    <img id="crypto_imgtasa_movil" />
    <img id="crypto_imgtasa_tw" />
  </div>
</template>

<script>
import api from "@/axios/index";
import apiv2 from "@/axios/apiv2";
import TronName from "../components/TronName";
import domtoimage from "dom-to-image-font-patch";
import FileSaver from "file-saver";
import { getCurName } from "../utils/curUtils";
import CryptoLegend from "../components/CryptoLegend";

export default {
  name: "CryptoHome",
  components: { CryptoLegend, "tron-name": TronName },
  mounted() {
    const me = this;
    me.fetchCryptoMarket();
    me.fetchAndSaveCryptoRates().then(() => {
      me.generateCryptoQualityImages();
    });
  },
  data() {
    const actualDate = new Date();
    const trmiStablishedDate = new Date(new Date().setHours(7, 0, 0, 0));
    const initialDate = new Date(
      new Date().setDate(trmiStablishedDate.getDate() - 1)
    );
    const cryptoInitialDate = new Date(
      new Date().setDate(trmiStablishedDate.getDate() - 1)
    );
    const fecha_vigente = actualDate;
    if (actualDate.getHours() < 7) {
      trmiStablishedDate.setDate(trmiStablishedDate.getDate() - 1);
      initialDate.setDate(trmiStablishedDate.getDate() - 1);
      cryptoInitialDate.setDate(trmiStablishedDate.getDate() - 1);
    }
    return {
      fecha_establecida: trmiStablishedDate.toLocaleDateString(),
      fecha_vigente: fecha_vigente.toLocaleDateString(),
      crypto_params: {
        msg: "false",
        date_from: `${cryptoInitialDate.getFullYear()}-${
          cryptoInitialDate.getMonth() + 1
        }-${cryptoInitialDate.getDate()} 07:00:00`,
        date_to: `${trmiStablishedDate.getFullYear()}-${
          trmiStablishedDate.getMonth() + 1
        }-${trmiStablishedDate.getDate()} 07:00:00`,
        x_cur: "all",
        token: "hRaCcY78gXC3k9WRv01pR7V1fgSxlg"
      },
      hora: "7:00 AM",
      cryptoCurrencies: ["BTC", "USDT_TRC20", "BNB", "TRX"],
      cryptoXrates: [], // [cur, cup, mlc, usd],
      cryptoMarket: [],
    };
  },
  methods: {
    // fetching data
    async getLastRateFromHistorical(cur, x_cur) {
      let data = (
        await api.get("x-rates-all-days-stats-by-currency", {
          params: {
            trmi: true,
            x_cur,
            cur,
          },
        })
      ).data;
      const lastRate = data[0];
      return {
        ...lastRate,
        p_median: lastRate.median,
        median_diff: 0,
      };
    },
    async fillBlanckCells() {
      await Promise.all(
        this.cryptoXrates.map(async (val, idx) => {
          const cur = val[0];
          const cup = val[1];
          const usd = val[3];

          if (!cup.median) {
            await this.getLastRateFromHistorical(cur, "CUP").then((rate) => {
              this.cryptoXrates[idx][1] = rate;
            });
          }
          if (cup.median && !cup.p_median) {
            await this.getLastRateFromHistorical(cur, "CUP").then((rate) => {
              const curVal = this.cryptoXrates[idx][1];
              this.cryptoXrates[idx][1] = {
                ...curVal,
                p_median: rate.median,
                median_diff: this.round2Places(curVal.median - rate.median),
              };
            });
          }
          if (!usd.median) {
            await this.getLastRateFromHistorical(cur, "USD").then((rate) => {
              this.cryptoXrates[idx][3] = rate;
            });
          }
          if (usd.median && !usd.p_median) {
            await this.getLastRateFromHistorical(cur, "USD").then((rate) => {
              const curVal = this.cryptoXrates[idx][3];
              this.cryptoXrates[idx][3] = {
                ...curVal,
                p_median: rate.median,
                median_diff: this.round2Places(curVal.median - rate.median),
              };
            });
          }
        })
      );
    },
    async fetchCryptoMarket() {
      const me = this;

      return api
        .get("crypto-market-listings-latest", {
          params: { established_date: me.crypto_params.date_to },
        })
        .then((res) => {
          me.cryptoMarket = res.data.crypto_market_cap;
        });
    },
    async fetchAndSaveCryptoRates() {
      const me = this;
      return apiv2
        .get("x-rates", { params: me.crypto_params })
        .then((res) => {
          let data = res.data;
          //console.log("data", data);
          let cryptoXrates = me.cryptoCurrencies.map((cur_short) => [
            cur_short,
            0,
            0,
            0,
          ]);
          //console.log(`crypto x rates`, cryptoXrates);
          let statistics = data.statistics;
          let i = 0;
          for (i; i < cryptoXrates.length; i++) {
            let crypto_short = cryptoXrates[i][0];
            if (
              Object.prototype.hasOwnProperty.call(
                statistics,
                `${crypto_short}.CUP`
              )
            ) {
              cryptoXrates[i][1] = statistics[`${crypto_short}.CUP`];
            }
            if (
              Object.prototype.hasOwnProperty.call(
                statistics,
                `${crypto_short}.MLC`
              )
            ) {
              cryptoXrates[i][2] = statistics[`${crypto_short}.MLC`];
            }
            if (
              Object.prototype.hasOwnProperty.call(
                statistics,
                `${crypto_short}.USD`
              )
            ) {
              cryptoXrates[i][3] = statistics[`${crypto_short}.USD`];
            }
          }
          me.cryptoXrates = cryptoXrates;
          //console.log(cryptoXrates);
        })
        .then(async () => {
          await this.fillBlanckCells();
        });
    },
    generateCryptoQualityImages() {
      this.saveImage(
        this.$refs.rates_crypto_table,
        2.9,
        true,
        "crypto_imgtasa"
      );
      this.saveImage(
        this.$refs.rates_crypto_table_story,
        3,
        true,
        "crypto_imgtasa_movil"
      );
      this.saveImage(
        this.$refs.rates_crypto_table_box,
        3,
        true,
        "crypto_imgtasa_tw"
      );
    },
    isTron(row) {
      return this.getCryptoShortCode(row) === "TRX";
    },
    saveImage(table, scale, show, id) {
      domtoimage
        .toBlob(table, {
          quality: 1,
          height: table.clientHeight * scale,
          width: table.clientWidth * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
          },
        })
        .then(function (blob) {
          if (show) {
            const objectURL = URL.createObjectURL(blob);
            const myImage = document.querySelector(`#${id}`);
            myImage.src = objectURL;
          } else {
            FileSaver.saveAs(blob, "TRMI.png");
          }
        });
    },
    getCryptoIconName(xrateRow) {
      return xrateRow[0].toLowerCase();
    },
    getCurrencyName(xrateRow) {
      const name = xrateRow[0];
      if (name === "ECU") {
        return "EUR";
      } else {
        return name;
      }
    },
    getCryptoShortCode(xrateRow) {
      const short = xrateRow[0];
      switch (short) {
        case "BTC":
          return "BTC";
        case "USDT_ERC20":
          return "USDT";
        case "USDT_TRC20":
          return "USDT";
        case "BNB":
          return "BNB";
        case "TRX":
          return "TRX";
        default:
          return short;
      }
    },
    getCryptoName(xrateRow) {
      let short = xrateRow[0].toUpperCase();
      return getCurName(short);
    },
    getCryptoDiff(xrateRow, curIdx) {
      let val = xrateRow[curIdx].median_diff;
      const symbol = xrateRow[0];
      if (symbol === "TRX") {
        val = this.getTrxMarketVal() * val;
      }
      val = parseFloat(val).toFixed(2);
      return `${this.getSign(val)}${val}`;
    },
    cryptoDiffNegative(xrateRow, curIdx) {
      let val = xrateRow[curIdx].median_diff;
      const symbol = xrateRow[0];
      if (symbol === "TRX") {
        val = this.getTrxMarketVal() * val;
        val = parseFloat(val.toFixed(2));
      }

      return val < 0;
    },
    cryptoDiffPositive(xrateRow, curIdx) {
      let val = xrateRow[curIdx].median_diff;
      const symbol = xrateRow[0];
      if (symbol === "TRX") {
        val = this.getTrxMarketVal() * val;
        val = parseFloat(val.toFixed(2));
      }

      return val > 0;
    },
    getTrxMarketVal() {
      return this.cryptoMarket.find((c) => c.symbol === "TRX")?.price;
    },
    getCryptoValue(xrateRow, curIdx) {
      let val = xrateRow[curIdx]?.median;
      const symbol = xrateRow[0];
      if (val) {
        if (symbol === "TRX") {
          val = this.getTrxMarketVal() * val;
        }
        return parseFloat(val).toFixed(2).replace(".", ",");
      } else {
        return "";
      }
    },
    round2Places(val) {
      return Math.round((val + Number.EPSILON) * 100) / 100;
    },
    getSign(val) {
      return val > 0 ? "+" : "";
    },
  },
};
</script>
<style src="./styles.css"></style>
<style scoped>
.unbordered-social-box {
  margin: 0 20px;
  padding: 1px 10px;
  color: #a6231c;
}
.black-body-row {
  border-bottom: 1px #000000 solid;
}

.black-right-border {
  border-right: 1px #000000 solid;
}

.crypto-down-icon {
  background-image: url(../assets/crypto-arrow-down.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.crypto-up-icon {
  background-image: url(../assets/crypto-up-arrow.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.btc-icon {
  background-image: url(../assets/icons/BTC.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bnb-icon {
  background-image: url(../assets/icons/BNB.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.trx-icon {
  background-image: url(../assets/icons/TRX.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.usdt_erc20-icon {
  background-image: url(../assets/icons/USDT_ERC20.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.usdt_trc20-icon {
  background-image: url(../assets/icons/usdttrc20_f180f676bb.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.crypto-negative-diff {
  color: var(--secondary-blue-color);
}

.crypto-positive-diff {
  color: var(--primary-red-color);
}
</style>
