<template>
  <div class="selectrange flex flex-wrap justify-center mx-0 sm:mx-28">
    <div class="flex-auto md:text-left text-center" v-show="showPeriods">
      <span
        @click="_onChange(7)"
        :class="{ 'selected-opt': days === 7 }"
        title="1 semana"
        >1S</span
      >
      |
      <span
        @click="_onChange(30)"
        :class="{ 'selected-opt': days === 30 }"
        title="1 mes"
        >1M</span
      >
      |
      <span
        @click="_onChange(92)"
        :class="{ 'selected-opt': days === 92 }"
        title="3 meses"
        >3M</span
      >
      |
      <span
        @click="_onChange(182)"
        :class="{ 'selected-opt': days === 182 }"
        title="6 meses"
        >6M</span
      >
      |
      <span
        @click="_onChange(365)"
        :class="{ 'selected-opt': days === 365 }"
        title="1 año"
        >1A</span
      >
    </div>
    <div class="flex-auto md:mt-0 mt-3">
      <litepie-datepicker
        i18n="es"
        as-single
        :formatter="formatter"
        use-range
        v-model="dateValue"
      ></litepie-datepicker>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateRangeSelectorComponent",
  props: {
    onChange: Function,
    showPeriods: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    dateValue: function (val) {
      console.log("date value", val);
      this.days = 0;
      this.onChange(val.length ? 0 : 7, val);
    },
  },
  data() {
    return {
      days: 7,
      formatter: {
        date: "YYYY-MM-DD",
        month: "MMM",
      },
      dateValue: [],
    };
  },
  methods: {
    _onChange(days) {
      this.days = days;
      this.onChange(days);
    },
  },
};
</script>

<style scoped>
.selectrange span {
  cursor: pointer;
  font-family: "Roboto-Medium", sans-serif;
  display: inline-block;
}

.selected-opt {
  font-weight: bolder;
  font-size: 20px;
}
/* Nuevos estilos para la vista móvil */
@media (max-width: 640px) { /* 640px es un breakpoint común para dispositivos móviles */
  .selectrange > div {
    width: 100%;  /* Hacer que los divs hijos ocupen todo el ancho */
    flex: none;   /* Anular la propiedad flex-auto */
  }
}
</style>
