<template>
  <bars-histogram ></bars-histogram>
</template>

<script>
import BarsHistogram from "../components/BarsHistogram.vue";

export default {
  name: "ChartHistogram",
  components: { BarsHistogram },
  data() {
    return {
      title: "Distribución de frecuencias por día",
    };
  },
};
</script>
