<template>
  <div ref="home" class="home">
    <div style="width: 95%; height: 400px; margin: 30px auto 0 auto">
      <div class="title-chart">
        {{ title }}
      </div>
      <!--      <ToggleButton-->
      <!--        class="justify-end toggle-container"-->
      <!--        :disabled="isLoading"-->
      <!--        v-model="showRealCryptoMarket"-->
      <!--      />-->
      <value-type-radio-group
        class="flex flex-col items-start"
        v-model="showRealCryptoMarket"
      ></value-type-radio-group>

      <chart-container :is-loading="isLoading">
        <v-chart v-if="option && !isLoading" class="chart" :option="option">
        </v-chart>
      </chart-container>
      <date-range-selector-component
        v-if="!isLoading || !!option"
        :on-change="onDateRangeChange"
      ></date-range-selector-component>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DateRangeSelectorComponent from "../components/DateRangeSelectorComponent";
import {
  getCryptoMarketByDateRange,
  getRatesByDateRange
} from "../utils/apiUtils";
import { getLegend } from "../utils/curUtils";
import { fillBlankSpaces, getDates } from "../utils/data";
// import ToggleButton from "../components/ToggleButton";
import ChartContainer from "../components/ChartContainer";
import ValueTypeRadioGroup from "../components/ValueTypeRadioGroup";
import { trunc } from "../utils/numberUtils";

export default {
  name: "CryptoLineChartView",
  components: {
    ValueTypeRadioGroup,
    ChartContainer,
    DateRangeSelectorComponent
    // ToggleButton,
  },
  mounted() {
    this.getOptions(7);
  },
  data() {
    return {
      isLoading: false,
      title:
        "Evolución del precio (CUP) de las criptomonedas en el mercado informal",
      currencies: ["BTC", "USDT_TRC20", "BNB", "TRX"],
      days: 7,
      formatter: {
        date: "YYYY-MM-DD",
        month: "MMM"
      },
      dateValue: [] /* Range entered on the input */,
      option: null,
      showRealCryptoMarket: true,
      market_rates: [],
      rates: [],
      dateList: []
    };
  },
  watch: {
    showRealCryptoMarket() {
      this.option = this._getOptions();
    }
  },
  methods: {
    onDateRangeChange(days, dateValue) {
      console.log("on date range change", days, " ", dateValue);
      this.days = days;
      this.dateValue = dateValue ? dateValue : [];
      this.getOptions(days, dateValue);
    },

    _getOptions() {
      let data = this.rates.map((curData, curDataIdx) => {
        let curMarketInfo = this.market_rates[curDataIdx];
        return curData.map((current, idx) => {
          // console.log(curMarketInfo[idx], idx, curMarketInfo);
          if (this.showRealCryptoMarket) {
            return current
              ? trunc(current * curMarketInfo?.[idx]?.quote?.USD?.price) ?? 1
              : null;
          } else {
            return current;
          }
        });
      });

      return {
        grid: {
          bottom: 30
        },
        color: [
          "#1636c1",
          "#d2011a",
          "#05ce7c",
          "#f99b2b",
          "#182360",
          "#1b5b20",
          "#6b05ce",
          "#cccc06"
        ],
        // Make gradient line here
        legend: {
          data: this.currencies.map((cur) => getLegend(cur)),
          show: true,
          top: 10
        },
        tooltip: {
          trigger: "axis"
          // formatter: '{b} | {c} CUP'
        },
        xAxis: [
          {
            data: this.dateList,
            axisLabel: {
              fontFamily: "Roboto-Medium"
            }
          }
          // {
          //   data: dateList,
          //   gridIndex: 1,
          // },
        ],
        yAxis: [
          {
            axisLabel: {
              fontFamily: "Roboto-Medium",
              formatter: function(value) {  // 3. Utilizar formato abreviado
                if (value >= 10000) {
                  return value / 10000 + 'k';
                }
                return value;
              }
            }
          }
          // {
          //   gridIndex: 1,
          // },
        ],
        series: data.map((el, i) => ({
          type: "line",
          showSymbol: true,
          smooth: false,
          // areaStyle: {},
          markPoint:
          // ["EUR", "USD", "MLC"].includes(currencies[i])
          // ?
            {
              data: [
                { type: "max", name: "Max" }
                // { type: "min", name: "Min" },
              ]
            },
          // : {},
          name: getLegend(this.currencies[i]),
          data: el
        }))
      };
    },
    getOptions(days, dates) {
      const me = this;
      me.days = days;

      me.isLoading = true;

      Promise.all(
        me.currencies.map(async (cur) => {
          return {
            rates: await getRatesByDateRange(cur, days, dates),
            market_rates: await getCryptoMarketByDateRange(
              cur === "USDT_TRC20" ? "USDT" : cur,
              days,
              dates
            )
          };
        })
      )
        .then((res) => {
          // todo: por algun motivo la consulta a la api me da un dia de mas
          // todo: revisar eso

          me.market_rates = res.map((resp) => {
            return resp.market_rates.data.crypto_market_cap_historical;
          });

          let data = res.map((resp) => {
            let v = resp.rates.data;
            let len = v.length;
            return v.slice(1, len);
          }); // Each data [{_id: 2021-12-26, median: 74.5200000001, min: 72: max: 84, last: {value: 74.64, date: '2021-12-27 05:05:03, first: {value: 74.3, date: '2021-12-26 14:23:00, avg: 75.87, count_values: 5}}}];
          const dateList = getDates(data, days, dates);
          me.dateList = dateList;
          // console.log(dateList);
          data = data.map((ratesByDate) => {
            const cleanArr = dateList.map((el) => ({ id: el, value: null }));
            for (const it of ratesByDate) {
              const i = cleanArr.findIndex((el) => it._id === el.id);
              if (i > -1) cleanArr[i].value = it.median;
            }
            return cleanArr.map((el) => el.value);
          });

          // Fill blank spaces
          data = data.map((ratesByDate) => fillBlankSpaces(ratesByDate));

          me.rates = data;
          // console.log(data);

          // if (me.showRealCryptoMarket) {
          //   data = data.map((curData, curDataIdx) => {
          //     let curMarketInfo = market_rates[curDataIdx];
          //     // console.log("market info", curMarketInfo);
          //     // console.log(curData);
          //     return curData.map((current, idx) => {
          //       // console.log(curMarketInfo[idx], idx, curMarketInfo);
          //       return current
          //         ? current * curMarketInfo?.[idx]?.quote?.USD?.price ?? 1
          //         : null;
          //     });
          //   });
          // }

          me.option = this._getOptions(dateList);
          me.isLoading = false;
        })
        .catch((e) => {
          me.isLoading = false;
          console.log(e);
        });
    }
  }
};
</script>

<style scoped>
.title-chart {
  font-family: "RobotoSlab-Bold", sans-serif;
}

.selectrange span {
  cursor: pointer;
  font-family: "Roboto-Medium", sans-serif;
  display: inline-block;
}

.toggle-container {
  margin-right: 7rem;
}

@media only screen and (max-width: 640px) {
  .toggle-container {
    margin-right: 0;
    justify-content: center;
  }
}
</style>
