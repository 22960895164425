<template>
  <CalcAllValues title="CONVERTIDOR DE MONEDAS" type="large" />
</template>

<script>
import CalcAllValues  from "../components/CalcAllValues";
export default {
  name: "CalcViews",
  components: {
    CalcAllValues
  },
  data() {
    return {
      title: "CONVERTIDOR DE MONEDAS",
    };
  },
}
</script>
<style scoped>

</style>
