<template>
  <cur-line-chart :title="title" :currencies="currencies"></cur-line-chart>
</template>

<script>
import CurLineChart from "../components/CurLineChart";

export default {
  name: "Chart",
  components: { CurLineChart },
  data() {
    return {
      title: "Evolución del precio (CUP) de las divisas en el mercado informal",
      currencies: ["USD", "ECU", "MLC"],
    };
  },
};
</script>
