const axios = require("axios");

const instance = axios.create({
  baseURL: "https://api.cambiocuba.money/api/v1/",
  timeout: 50000,
});

exports.post = function (url, data, config) {
  return instance.post(url, data, config);
};

exports.get = function get(url, config) {
  return instance.get(url, config);
};

exports.put = function put(url, data, config) {
  return instance.put(url, data, config);
};

exports._delete = function _delete(url, config) {
  return instance.delete(url, config);
};
