<template>
  <div class="home">
    <div class="save-buttons-container">
      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="saveImage($refs.rates_table, 2.9)"
      >
        Guardar POST
      </button>
      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="saveImage($refs.rates_table_story, 3)"
      >
        Guardar Storie
      </button>
      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="saveImage($refs.rates_table_box, 3)"
      >
        Guardar BOX
      </button>
      <button
        class="
          p-3
          m-1
          items-center
          justify-center
          rounded-md
          bg-black
          text-white
        "
        @click="
          saveImageDirect('https://wa.cambiocuba.money/media_informal.png')
        "
      >
        Guardar Buy/Sell
      </button>
      <router-link class="ml-4 underline" to="/crypto-rates"
        >Ir a las criptomonedas
      </router-link>
      <p class="mt-3">
        Fecha y hora en la Habana en el servidor:
        <span
          v-if="showHavanaDate()"
          :class="{ 'red-text': !isCurrentDatePassSevenAm }"
          >{{ havanaDateTimeString }}</span
        >
      </p>
      <p>
        <span
          v-if="showHavanaDate() && !isCurrentDatePassSevenAm"
          class="red-text"
          >Hora no recomendada para establecer la tasa correspondiente a
          {{ fecha_establecida }}</span
        >
      </p>
      <div class="flex flex-row justify-center">
        <p class="m-3">
          Cantidad de mensajes (MLC, EUR, USD):
          <span>{{ messagesCount }}</span>
        </p>
        <button
          class="
            p-1
            px-2
            m-1
            items-center
            justify-center
            rounded-md
            bg-black
            text-white
          "
          @click="copyText2Clipboard"
        >
          Copiar en portapapeles
        </button>
      </div>
    </div>
    <div class="first-images-row flex gap-1">
      <div
        class="portrait-image portrait-table-container flex-none"
        ref="rates_table_story"
      >
        <div class="box-rates-title-story text-center">
          <div class="my-0 mx-auto" style="width: 15%">
            <img class="w-60" src="@/assets/logo.png" alt="" />
          </div>
          <div class="my-0 mx-auto" style="padding: 20px 0 30px 0">
            <p
              class="
                box-title-story
                text-white
                roboto-slab
                text-upper text-bold
                mb-0
              "
            >
              Tasa representativa
            </p>
            <p
              class="
                box-title-story
                text-white
                roboto-slab
                text-upper text-bold
                mt-0
              "
            >
              del mercado informal
            </p>
            <p
              class="
                box-title-story
                text-white
                roboto-slab
                text-upper text-bold
                mt-0
              "
            >
              de divisas en Cuba
            </p>
          </div>
        </div>
        <div
          class="box-rates-body-story"
          style="background-color: var(--light-blue-color)"
        >
          <div class="values">
            <div
              :key="r[0]"
              v-for="r in xrates"
              class="body-row flex justify-center"
            >
              <div class="currency-box currency-box-story roboto-bold w-1/4">
                <div class="flex items-center justify-center">
                  <div>
                    1 {{ getCurrencyName(r) }}
                    <span v-if="isCUPE(r)">
                      <p
                        class="mlc-quote"
                        style="margin-left: 10px; font-size: 7px"
                      >
                        efectivo
                      </p>
                    </span>
                  </div>
                  <div style="margin-left: 14px; margin-top: -2px">
                    <span
                      :class="[
                        'flag-icon',
                        `flag-icon-${getCurrencyFlagCode(r)}`,
                        'currency-icon',
                      ]"
                    ></span>
                  </div>
                </div>
                <div v-if="isMLC(r)">
                  <p class="mlc-quote" style="margin: 0; font-size: 7px">
                    USD en saldo de cuenta bancaria
                  </p>
                </div>
              </div>

              <div
                :class="[
                  'roboto-bold',
                  'currency-box',
                  'value-box',
                  {
                    'negative-diff': diffNegative(r),
                    'positive-diff': diffPositive(r),
                    'zero-diff': !diffNegative && !diffNegative,
                  },
                  'flex',
                  'items-center',
                  'box-border',
                ]"
                style="padding-left: 5px; width: 41%"
              >
                <div>
                  <span style="visibility: hidden" v-if="isCUPE(r)">22</span
                  >{{ getValue(r) }} CUP
                  <span v-if="isCUPE(r)">
                    <p
                      class="mlc-quote"
                      style="margin-right: 3px; text-align: end; font-size: 7px"
                    >
                      transferencia
                    </p>
                  </span>
                </div>
                <div
                  :class="[
                    {
                      'down-icon': diffNegative(r),
                      'up-icon': diffPositive(r),
                    },
                  ]"
                  style="
                    width: 20px;
                    height: 13px;
                    margin-left: 5px;
                    margin-bottom: 2px;
                  "
                ></div>

                <span
                  v-if="diffPositive(r) || diffNegative(r)"
                  style="font-size: 9px; margin-bottom: 10px"
                >
                  {{ getDiff(r) }}
                </span>
              </div>
              <div
                class="
                  roboto-bold
                  flex
                  items-center
                  justify-center
                  box-border
                  w-1/4
                "
                style="padding-left: 5px"
              >
                {{ entries[r[0]] }}
              </div>
            </div>
          </div>
          <div
            class="text-italic text-upper"
            style="padding-top: 60px; font-size: 9px"
          >
            <span>establecida: </span>
            <span class="text-bold space-4">{{ fecha_establecida }}</span>
            <span class="text-bold space-2">{{ hora }}</span>
            <span class="text-bold space-4">Cuba</span>
            <br />
            <br />
            <span>Vigente: </span>
            <span class="text-bold">{{ fecha_vigente }}</span>
          </div>
          <div
            style="margin-top: 40px; font-size: 11px"
            class="roboto-slab text-bold"
          >
            <span class="social-box">@eltoquecom</span>
            <span class="social-box">eltoque.com</span>
          </div>
        </div>
      </div>

      <div
        class="wide-screen-image flex-none block"
        ref="rates_table"
        style="width: 600px; height: 352px"
      >
        <div
          class="flex text-left justify-between"
          style="
            background-color: var(--primary-blue-color);
            padding: 10px 40px;
          "
        >
          <div class="w-3/4" style="margin-top: 10px">
            <p
              class="box-title text-white roboto-slab text-upper text-bold mb-0"
            >
              Tasa representativa del mercado
            </p>
            <p
              class="box-title text-white roboto-slab text-upper text-bold mt-0"
            >
              informal de divisas en Cuba
            </p>
          </div>
          <div class="flex flex-col justify-center" style="width: 15%">
            <img width="50" src="@/assets/logo.png" alt="" />
          </div>
        </div>
        <div class="box-rates-body" style="background-color: #b0cdeb">
          <div class="values">
            <div
              :key="r[0]"
              v-for="r in xrates"
              class="body-row flex justify-center"
            >
              <div class="currency-box roboto-bold w-1/3">
                <div class="flex items-center justify-center">
                  <div style="margin-right: 30px">
                    1 {{ getCurrencyName(r) }}
                    <span v-if="isCUPE(r)">
                      <p
                        class="mlc-quote"
                        style="margin-left: 10px; font-size: 7px"
                      >
                        efectivo
                      </p>
                    </span>
                  </div>
                  <div>
                    <span
                      style="width: 40px; height: 40px"
                      :class="[
                        'flag-icon',
                        `flag-icon-${getCurrencyFlagCode(r)}`,
                      ]"
                    ></span>
                  </div>
                </div>
                <div v-if="isMLC(r)">
                  <p class="mlc-quote" style="margin: 0">
                    USD en saldo de cuenta bancaria
                  </p>
                </div>
              </div>
              <div
                :class="[
                  'roboto-bold',
                  'value-box',
                  {
                    'negative-diff': diffNegative(r),
                    'positive-diff': diffPositive(r),
                    'zero-diff': !diffNegative && !diffNegative,
                  },
                  'flex',
                  'currency-box',
                  'items-center',
                  'box-border',
                ]"
                style="padding-left: 40px; width: 40%"
              >
                <div>
                  <span style="visibility: hidden" v-if="isCUPE(r)">22</span
                  >{{ getValue(r) }} CUP
                  <span v-if="isCUPE(r)">
                    <p
                      class="mlc-quote"
                      style="margin-right: 3px; text-align: end; font-size: 7px"
                    >
                      transferencia
                    </p>
                  </span>
                </div>
                <div
                  :class="[
                    {
                      'down-icon': diffNegative(r),
                      'up-icon': diffPositive(r),
                    },
                  ]"
                  style="
                    width: 20px;
                    height: 13px;
                    margin-left: 5px;
                    margin-bottom: 2px;
                  "
                ></div>
                <span
                  v-if="diffPositive(r) || diffNegative(r)"
                  style="font-size: 9px; margin-bottom: 10px"
                  >{{ getDiff(r) }}</span
                >
              </div>
              <div
                class="
                  roboto-bold
                  flex
                  items-center
                  justify-center
                  box-border
                  w-1/4
                "
                style="padding-left: 5px"
              >
                {{ entries[r[0]] }}
              </div>
            </div>
          </div>

          <div
            class="text-italic text-upper"
            style="padding-top: 30px; font-size: 9px"
          >
            <span>establecida: </span>
            <span class="text-bold space-4">{{ fecha_establecida }}</span>
            <span class="text-bold space-2">{{ hora }}</span>
            <span class="text-bold space-4">Cuba</span>
            <span>Vigente: </span>
            <span class="text-bold">{{ fecha_vigente }}</span>
          </div>
          <div
            style="margin-top: 20px; font-size: 11px"
            class="roboto-slab text-bold"
          >
            <span class="social-box">@eltoquecom</span>
            <span class="social-box">eltoque.com</span>
          </div>
        </div>
      </div>

      <div
        class="flex-none squared-image block"
        ref="rates_table_box"
        style="width: 540px; height: 540px"
      >
        <div
          class="flex text-left justify-between"
          style="
            background-color: var(--primary-blue-color);
            padding: 20px 60px;
          "
        >
          <div class="w-4/5" style="margin-top: 10px">
            <p
              class="
                box-title box-title-box
                text-white
                roboto-slab
                text-upper text-bold
                mb-0
              "
            >
              Tasa representativa
            </p>
            <p
              class="
                box-title box-title-box
                text-white
                roboto-slab
                text-upper text-bold
                mb-0
              "
            >
              del mercado informal
            </p>
            <p
              class="
                box-title box-title-box
                text-white
                roboto-slab
                text-upper text-bold
                mt-0
              "
            >
              de divisas en Cuba
            </p>
          </div>
          <div class="flex flex-col justify-center" style="width: 15%">
            <img width="70" src="@/assets/logo.png" alt="" />
          </div>
        </div>
        <div
          class="box-rates-body-box"
          style="background-color: var(--light-blue-color)"
        >
          <div class="values">
            <div
              :key="r[0]"
              v-for="r in xrates"
              class="body-row flex justify-center"
            >
              <div class="currency-box roboto-bold w-1/3">
                <div class="flex items-center justify-center">
                  <div style="margin-right: 30px">
                    1 {{ getCurrencyName(r) }}
                    <span v-if="isCUPE(r)">
                      <p
                        class="mlc-quote"
                        style="margin-left: 10px; font-size: 7px"
                      >
                        efectivo
                      </p>
                    </span>
                  </div>
                  <div>
                    <span
                      style="width: 40px; height: 40px"
                      :class="[
                        'flag-icon',
                        `flag-icon-${getCurrencyFlagCode(r)}`,
                      ]"
                    ></span>
                  </div>
                </div>
                <div v-if="isMLC(r)">
                  <p class="mlc-quote" style="margin: 0">
                    USD en saldo de cuenta bancaria
                  </p>
                </div>
              </div>
              <div
                :class="[
                  'roboto-bold',
                  'value-box',
                  {
                    'negative-diff': diffNegative(r),
                    'positive-diff': diffPositive(r),
                    'zero-diff': !diffNegative && !diffNegative,
                  },
                  'flex',
                  'currency-box',
                  'justify-center',
                  'items-center',
                  'box-border',
                ]"
                style="width: 40%"
              >
                <div>
                  <span style="visibility: hidden" v-if="isCUPE(r)">22</span
                  >{{ getValue(r) }} CUP
                  <span v-if="isCUPE(r)">
                    <p
                      class="mlc-quote"
                      style="margin-right: 3px; text-align: end; font-size: 7px"
                    >
                      transferencia
                    </p>
                  </span>
                </div>
                <div
                  :class="[
                    {
                      'down-icon': diffNegative(r),
                      'up-icon': diffPositive(r),
                    },
                  ]"
                  style="
                    width: 20px;
                    height: 13px;
                    margin-left: 5px;
                    margin-bottom: 2px;
                  "
                ></div>
                <span
                  v-if="diffPositive(r) || diffNegative(r)"
                  style="font-size: 9px; margin-bottom: 10px"
                  >{{ getDiff(r) }}</span
                >
              </div>
              <div
                class="
                  roboto-bold
                  flex
                  items-center
                  justify-center
                  box-border
                  w-1/4
                "
                style="padding-left: 5px"
              >
                {{ entries[r[0]] }}
              </div>
            </div>
          </div>

          <div
            class="text-italic text-upper"
            style="padding-top: 60px; font-size: 13px !important"
          >
            <span>establecida: </span>
            <span class="text-bold space-4">{{ fecha_establecida }}</span>
            <span class="text-bold space-2">{{ hora }}</span>
            <span class="text-bold space-4">Cuba</span>
            <span>Vigente: </span>
            <span class="text-bold">{{ fecha_vigente }}</span>
          </div>
          <div
            style="margin-top: 50px; font-size: 14px"
            class="roboto-slab text-bold"
          >
            <span class="social-box">@eltoquecom</span>
            <span class="social-box">eltoque.com</span>
          </div>
        </div>
      </div>
    </div>
    <!--
        <div style="width: 400px">
          <p>¡Buenos días! 👋</p>

          <p>
            Esta es nuestra #tasa representativa del mercado informal de #divisas en
            #Cuba 🇨🇺 para el {{ fecha_establecida }}.
          </p>

          <p>
            ¿Cómo elaboramos la tasa? ¿Qué metodología utilizamos? Puedes obtener
            más información aquí:
          </p>
          <p>► <a href="http://bit.ly/3dO2j66">http://bit.ly/3dO2j66</a></p>
        </div> -->
    <img id="imgtasa" />
    <img id="imgtasa_movil" />
    <img id="imgtasa_tw" />

    <!-- <div style="display: flex; justify-content: center; margin-top: 10px">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: fit-content;
        "
      >
        <label>
          Establecida
          <input v-model="fecha_establecida" />
        </label>
         <label>
          Hora
          <input v-model="hora" />
        </label>
        <label>
          Vigente
          <input v-model="fecha_vigente" />
        </label>
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import api from "@/axios/index";
import domtoimage from "dom-to-image-font-patch";
import FileSaver from "file-saver";
import { ExchangeV2 } from "./../utils/currency";

export default {
  name: "Home",
  mounted() {
    const me = this;
    me.fetchAndSaveTRMI().then(() => {
      me.generateTRMIQualityImages();
    });
  },
  data() {
    const actualDate = new Date();
    const trmiStablishedDate = new Date(new Date().setHours(7, 0, 0, 0));
    const initialDate = new Date(
      new Date().setDate(trmiStablishedDate.getDate() - 1)
    );
    const cryptoInitialDate = new Date(
      new Date().setDate(trmiStablishedDate.getDate() - 1)
    );
    // Calculate the new date_from for cpue_params which is 7 days before date_to
    const cpueDateFrom = new Date(trmiStablishedDate);
    cpueDateFrom.setDate(cpueDateFrom.getDate() - 5);
    const fecha_vigente = actualDate;
    if (actualDate.getHours() < 7) {
      trmiStablishedDate.setDate(trmiStablishedDate.getDate() - 1);
      initialDate.setDate(trmiStablishedDate.getDate() - 1);
      cryptoInitialDate.setDate(trmiStablishedDate.getDate() - 1);
    }
    return {
      fecha_establecida: trmiStablishedDate.toLocaleDateString(),
      fecha_vigente: fecha_vigente.toLocaleDateString(),
      exchange: new ExchangeV2(),
      trmi_params: {
        msg: "false",
        date_from: `${initialDate.getFullYear()}-${
          initialDate.getMonth() + 1
        }-${initialDate.getDate()} 07:00:00`,
        date_to: `${trmiStablishedDate.getFullYear()}-${
          trmiStablishedDate.getMonth() + 1
        }-${trmiStablishedDate.getDate()} 07:00:00`,
        token: "hRaCcY78gXC3k9WRv01pR7V1fgSxlg"
      },
      cpue_params: {
        date_from: `${cpueDateFrom.getFullYear()}-${
          cpueDateFrom.getMonth() + 1
        }-${cpueDateFrom.getDate()} 07:00:00`,
        date_to: `${trmiStablishedDate.getFullYear()}-${
          trmiStablishedDate.getMonth() + 1
        }-${trmiStablishedDate.getDate()} 07:00:00`,
      },
      hora: "7:00 AM",
      image_url: "pass",
      entries: { USD: "- MLC", ECU: "- MLC", MLC: "-", CUP: "-" },
      xrates: [],
      havanaDate: "",
      havanaTime: {
        hour: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  computed: {
    havanaDateTime() {
      const date = new Date(
        `${this.havanaDate}T${this.formatNumber(
          this.havanaTime.hour
        )}:${this.formatNumber(this.havanaTime.minutes)}:${this.formatNumber(
          this.havanaTime.seconds
        )}`
      );
      console.log(
        "Havana Date Time",
        `${this.havanaDate}T${this.havanaTime.hour}:${this.havanaTime.minutes}:${this.havanaTime.seconds}`
      );
      console.log(date);
      return date;
    },
    havanaDateTimeString() {
      return `${this.havanaDateTime?.toLocaleString() ?? ""}`;
    },
    isCurrentDatePassSevenAm() {
      return !(
        this.havanaDateTime?.getTime() <
        new Date(this.fecha_establecida).getTime()
      );
    },
    messagesCount() {
      try {
        return (
          this.xrates[0][1].count_messages +
          this.xrates[1][1].count_messages +
          this.xrates[2][1].count_messages
        );
      } catch (e) {
        return "";
      }
    },
  },
  methods: {
    copyText2Clipboard() {
      try {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.messagesCount);
        /* Alert the copied text */
        alert("Texto copiado al portapapeles; " + this.messagesCount);
      } catch (e) {
        alert("No funciona en tu navegador :(");
      }
    },
    // utils
    formatNumber(n) {
      return n.toString().padStart(2, "0");
    },
    showHavanaDate() {
      return this.havanaDate !== "";
    },
    // fetching data
    async fetchAndSaveTRMI() {
      const me = this;
      return api
        .get("x-rates", { params: me.trmi_params })
        .then(async (res) => {
          // statistics: {
          //  USD: {avg, avg_diff, count_messages, count_values, max, max_diff, median, median_diff, min, min_diff, p_avg, p_count_messages, p_count_values, p_max, p_median, p_min}
          //  }
          let data = res.data;
          let statistics = Object.entries(data.statistics);
          statistics = statistics.filter((e) => me.isMLCEurOrUsd(e[0]));
          statistics = statistics.sort((r1, r2) => {
            return me.curCompare(r1[0], r2[0]);
          });
          me.xrates = statistics;
          me.havanaDate = data.date;
          me.havanaTime = {
            hour: data.hour,
            minutes: data.minutes,
            seconds: data.seconds,
          };

          try {
            const result = await me.exchange.convert({
              currency: "MLC",
              minimumCountMessages: 10,
              countDaysBefore: 1,
            });
            me.entries["USD"] = result["MLC"]["USD"]
              ? `${parseFloat(1 / result["MLC"]["USD"].value).toFixed(2)} MLC`
              : "-";
            me.entries["ECU"] = result["MLC"]["ECU"]
              ? `${parseFloat(1 / result["MLC"]["ECU"].value).toFixed(2)} MLC`
              : "-";

            // const cupe = await api.get("x-rates-cup-statistics", { params: me.cpue_params });
            // if (cupe.data.statistics && cupe.data.statistics.median) {
            //   cupe.data.statistics.median_diff = 0;
            //   console.log("CUP", cupe.data.statistics);
            //   me.xrates.push(["CUP", cupe.data.statistics]);
            // }
          } catch (e) {
            console.log(e);
          }
        });
    },
    getCurrencyStyle(currency) {
      let currencyStyle = currency;
      if (currencyStyle == "USDT_TRC20") {
        currencyStyle = "USDT";
      }
      if (currencyStyle == "ECU") {
        currencyStyle = "EUR";
      }
      return currencyStyle;
    },
    generateTRMIQualityImages() {
      this.saveImage(this.$refs.rates_table, 2.9, true, "imgtasa");
      this.saveImage(this.$refs.rates_table_story, 3, true, "imgtasa_movil");
      this.saveImage(this.$refs.rates_table_box, 3, true, "imgtasa_tw");
    },
    saveImageDirect(url) {
      FileSaver.saveAs(url, "mediana_compra_venta.png");
    },
    // utils
    isMLCEurOrUsd(curShort) {
      return ["ECU", "MLC", "USD"].includes(curShort);
    },
    curCompare(cur1, cur2) {
      if (cur1 === "MLC") {
        return 1;
      } else if (cur1 === "ECU") {
        return -1;
      } else if (cur2 === "MLC") {
        return -1;
      } else if (cur2 === "ECU") {
        return 1;
      } else {
        return 0;
      }
    },
    isMLC(r) {
      return this.getCurrencyName(r) === "MLC";
    },
    isCUPE(r) {
      return this.getCurrencyName(r) === "CUP";
    },
    saveImage(table, scale, show, id) {
      domtoimage
        .toBlob(table, {
          quality: 1,
          height: table.clientHeight * scale,
          width: table.clientWidth * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
          },
        })
        .then(function (blob) {
          if (show) {
            const objectURL = URL.createObjectURL(blob);
            const myImage = document.querySelector(`#${id}`);
            myImage.src = objectURL;
          } else {
            FileSaver.saveAs(blob, "TRMI.png");
          }
        });
    },
    getDiff(xrateRow) {
      const val = xrateRow[1].median_diff;
      return `${this.getSign(val)}${val}`;
    },
    getSign(val) {
      return val > 0 ? "+" : "";
    },
    diffNegative(xrateRow) {
      return xrateRow[1].median_diff < 0;
    },
    diffPositive(xrateRow) {
      return xrateRow[1].median_diff > 0;
    },

    getValue(xrateRow) {
      return parseFloat(xrateRow[1].median).toFixed(2); //.replace(".", ",");
    },
    getCurrencyFlagCode(xrateRow) {
      const cur = xrateRow[0];
      switch (cur) {
        case "MLC":
          return "mlc";
        case "USD":
          return "us";
        case "ECU":
          return "eu";
        case "CUP":
          return "cu";
      }
    },
    getCurrencyName(xrateRow) {
      const name = xrateRow[0];
      if (name === "ECU") {
        return "EUR";
      } else {
        return name;
      }
    },
  },
};
</script>

<style src="./styles.css"></style>

<style>
.red-text {
  color: red;
}

.box-title-story {
  font-size: 17px;
  letter-spacing: 1.5px;
}

.box-title-box {
  font-size: 18px !important;
  letter-spacing: 1.8px !important;
}

.currency-icon {
  width: 40px;
  height: 40px;
}

.social-box {
  margin: 0 20px;
  padding: 1px 10px;
  border: 0.7px solid #1f4a7f;
  color: #1f4a7f;
}

.box-rates-body-box {
  padding: 60px 10%;
  font-size: 18px;
}

.box-rates-body-story {
  padding: 70px 5% 50px 5%;
  font-size: 16px;
}

.text-upper {
  text-transform: uppercase;
}

.mlc-quote {
  margin-top: -5px !important;
  font-family: RobotoSlab-Regular;
  font-weight: 100;
  font-size: 8.5px;
}

.body-row {
  border-bottom: 1px white solid;
}

.currency-box {
  border-right: 1px white solid;
}

.currency-box-story {
  font-size: 16px;
  min-width: 112px;
}

.space-4 {
  margin-right: 20px;
}

.down-icon {
  background-image: url(../assets/TriangleArrow-Down.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.up-icon {
  background-image: url(../assets/up-arrow.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.flag-icon-mlc {
  background-image: url(../assets/card.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.negative-diff {
  color: #1f4a7f;
}

.positive-diff {
  color: #ec1d26;
}

.selectrange span {
  cursor: pointer;
  font-family: "Roboto-Medium";
  display: inline-block;
}

.selectrange {
  text-align: left;
  padding-bottom: 20px;
  margin-left: 10%;
}

.selected-opt {
  font-weight: bolder;
  font-size: 20px;
}

.title-chart {
  font-family: "RobotoSlab-Bold";
}

/*.btn-primary {*/
/*  @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;*/
/*}*/
</style>
