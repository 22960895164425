<template>
  <day-users-line-chart :title="title" ></day-users-line-chart>
</template>

<script>
import DayUsersLineChart from "@/components/DayUsersLineChart.vue";

export default {
  name: "ChartNewUsers",
  components: { DayUsersLineChart },
  data() {
    return {
      title: "Usuarios nuevos registrados en la plataforma",
    };
  },
};
</script>
