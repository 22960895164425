<template>
  <div class="home">
    <div class="control-panel" style="width: 95%; margin: 30px auto 0 auto">
      <div>
        <label>Bins:</label>
        <input min="1" type="number" v-model="bins" placeholder="Bins" />
      </div>
      <div>
        <label>Fecha:</label> <input type="date" v-model="selectedDate" />
      </div>
      <div>
        <label>Moneda:</label>
        <select v-model="selectedCurrency">
          <option value="USD">USD</option>
          <option value="USDT_TRC20">USDT</option>
          <option value="ECU">EUR</option>
          <option value="MLC">MLC</option>
        </select>
      </div>
      <div>
        <input type="checkbox" v-model="showPrev" id="semanaant" />
        <label for="semanaant"> Ver semana anterior</label>
      </div>
    </div>
    <button @click="copyToClipboard">Copiar al portapapeles</button>

    <chart-container :is-loading="isLoading">
      <v-chart v-if="option && !isLoading" class="chart" :option="option">
      </v-chart>
    </chart-container>
    <div>
      <table class="statistics-table">
        <tr>
          <th></th>
          <th>{{ selectedDate }}</th>
          <th v-if="showPrev">{{ previousWeekDate }}</th>
        </tr>
        <tr>
          <td>std</td>
          <td>{{ std.toFixed(4) }}</td>
          <td v-if="showPrev">{{ prevStd.toFixed(4) }}</td>
        </tr>
        <tr>
          <td>Estadístico W:</td>
          <td>{{ shapiroWilkStats.toFixed(4) }}</td>
          <td v-if="showPrev">{{ shapiroWilkStats.toFixed(4) }}</td>
        </tr>
        <tr>
          <td>P_VALUE:</td>
          <td>{{ shapiroWilkPValue }}</td>
          <td v-if="showPrev">{{ shapiroWilkPValue }}</td>
        </tr>
        <tr>
          <td>Distribución:</td>
          <td>{{ esnormal }}</td>
          <td v-if="showPrev">{{ prevEsnormal }}</td>
        </tr>
        <tr>
          <td>mediana</td>
          <td>{{ median }}</td>
          <td v-if="showPrev">{{ prevMedian }}</td>
        </tr>
        <tr>
          <td>moda</td>
          <td>{{ mode }}</td>
          <td v-if="showPrev">{{ prevMode }}</td>
        </tr>
        <tr>
          <td>media</td>
          <td>{{ mean.toFixed(4) }}</td>
          <td v-if="showPrev">{{ prevMean.toFixed(4) }}</td>
        </tr>
        <tr>
          <td>skewness</td>
          <td>{{ skewness.toFixed(4) }}</td>
          <td v-if="showPrev">{{ prevSkewness.toFixed(4) }}</td>
        </tr>
        <tr>
          <td>kurtosis</td>
          <td>{{ kurtosis.toFixed(4) }}</td>
          <td v-if="showPrev">{{ prevKurtosis.toFixed(4) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ChartContainer from "../components/ChartContainer";
import * as ss from "simple-statistics";
import ShapiroWilk from "../utils/shapirowilk";

export default {
  name: "HistogramChart",
  components: { ChartContainer },
  props: {
    title: String,
    currencies: Array,
    initialSelectedCur: String,
  },
  watch: {
    showPrev: {
      handler: "getOptions",
    },
    bins: {
      handler: "getOptions",
    },
    selectedDate: {
      handler: "getOptions",
    },
    selectedCurrency: {
      handler: "getOptions",
    },
    seriesData: {
      handler: "evaluateNormality",
    },
  },
  data() {
    return {
      isLoading: false,
      selectedDate: new Date().toISOString().split("T")[0],
      previousWeekDate: this.getPreviousWeekDate(
        new Date().toISOString().split("T")[0]
      ),
      bins: 10,
      showPrev: false,
      selectedCurrency: "USD",
      offerType: "General",
      bottom: 0,
      top: 0,
      prevBottom: 0,
      prevTop: 0,
      lowerBound: 0,
      upperBound: 0,
      prevLowerBound: 0,
      prevUpperBound: 0,
      esnormal: "Unknown",
      prevEsnormal: "Unknown",
      option: null,
      shapiroWilkPValue: 0,
      shapiroWilkStats: 0,
      prevShapiroWilkPValue: 0,
      prevShapiroWilkStats: 0,
      skewness: 0,
      prevSkewness: 0,
      kurtosis: 0,
      prevKurtosis: 0,
      median: 0,
      prevMedian: 0,
      mode: 0,
      prevMode: 0,
      mean: 0,
      prevMean: 0,
      std: 0,
      prevStd: 0,
      seriesData: [],
      prevSeriesData: [],
      expandendData: [],
      prevExpandendData: [],
      data: [],
      prevData: [],
    };
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    exportHistogramData(series) {
      let csvContent = ""; // Encabezado del CSV
      series.forEach((serie) => {
        if (serie.name && serie.name.includes("Histograma")) {
          csvContent += `${serie.name}\n`;

          // Añadir los encabezados de las columnas
          csvContent += "xData,yData\n";

          // Añadir cada punto de datos como una fila en la tabla
          serie.data.forEach((point) => {
            if (point[0] && point[1]) csvContent += `${point[0]},${point[1]}\n`;
          });

          // Añadir una línea en blanco para separar las tablas
          csvContent += "\n";
        }
      });

      return csvContent;
    },
    getPreviousWeekDate(date) {
      const d = new Date(date);
      d.setDate(d.getDate() - 7);
      return d.toISOString().split("T")[0];
    },
    evaluateNormality() {
      const evaluate = (data) => {
        if (!data || data.length < 1) return { isNormal: "Unknown", pValue: 0 };
        const mean = ss.mean(data);
        const standardDeviation = ss.standardDeviation(data);

        const normalizedData = data.map(
          (value) => (value - mean) / standardDeviation
        );
        const shapiroWilk = ShapiroWilk(normalizedData);
        console.log(data, "normalizedData");
        console.log(shapiroWilk, "shapiroWilk");
        const isNormal = shapiroWilk.pValue > 0.05;
        return {
          isNormal: isNormal ? "Normal" : "Not normal",
          estadistico: shapiroWilk.W,
          pValue: shapiroWilk.pValue,
        };
      };
      const currentEval = evaluate(this.expandendData);
      const prevEval = evaluate(this.prevExpandendData);
      this.esnormal = currentEval.isNormal;
      this.shapiroWilkPValue = currentEval.pValue;
      this.shapiroWilkStats = currentEval.estadistico;
      this.prevEsnormal = prevEval.isNormal;
      this.prevShapiroWilkPValue = prevEval.pValue;
      this.prevShapiroWilkStats = prevEval.estadistico;
    },
    async copyToClipboard() {
      const data = this.exportHistogramData(this.option.series);
      try {
        await navigator.clipboard.writeText(data);
        alert("Datos copiados al portapapeles");
      } catch (err) {
        console.error("Error al copiar al portapapeles", err);
      }
    },
    async fetchData(date) {
      const dateParts = date.split("-");
      const dateFrom = new Date(
        Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2])
      );
      dateFrom.setUTCHours(7, 0, 0);
      dateFrom.setUTCDate(dateFrom.getUTCDate() - 1);
      const dateTo = new Date(
        Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2])
      );
      dateTo.setUTCHours(7, 0, 0);
      let dateFromString = dateFrom.toISOString();
      let dateToString = dateTo.toISOString();
      dateFromString = dateFromString
        .replace("T", " ")
        .replace("Z", "")
        .substring(0, dateFromString.length - 5);
      dateToString = dateToString
        .replace("T", " ")
        .replace("Z", "")
        .substring(0, dateToString.length - 5);

      let url = `https://api.cambiocuba.money/api/v1/frequencies?x_cur=${this.selectedCurrency}&date_from=${dateFromString}&date_to=${dateToString}`;
      if (this.offerType !== "General") {
        url += `&offer=${this.offerType}`;
      }
      const response = await fetch(url);
      const data = await response.json();
      return data;
    },
    async getOptions() {
      const me = this;
      me.isLoading = true;
      me.data = await this.fetchData(this.selectedDate);
      me.previousWeekDate = this.getPreviousWeekDate(this.selectedDate);
      me.prevData = await this.fetchData(this.previousWeekDate);

      const processSeriesData = (data) => {
        let expandedData = [];
        data.forEach((item) => {
          for (let i = 0; i < item.count; i++) {
            expandedData.push(item._id.value);
          }
        });
        return expandedData;
      };
      const calculateStats = (data) => {
        let mean = ss.mean(data);
        let stdDev = ss.standardDeviation(data);
        const top = Math.floor(mean + 3 * stdDev);
        const bottom = Math.floor(mean - 3 * stdDev);
        // Remove from data values that are outside 3 standard deviations
        data = data.filter((value) => value >= bottom && value <= top);

        const median = ss.median(data);
        mean = ss.mean(data);
        stdDev = ss.standardDeviation(data);
        const mode = ss.mode(data);
        const skewness = ss.sampleSkewness(data);
        const kurtosis = ss.sampleKurtosis(data);
        const upperBound = Math.floor(mean + 2 * stdDev);
        const lowerBound = Math.floor(mean - 2 * stdDev);

        return {
          median,
          mean,
          mode,
          stdDev,
          skewness,
          kurtosis,
          top,
          bottom,
          upperBound,
          lowerBound,
        };
      };

      const seriesData = processSeriesData(me.data);
      const prevSeriesData = processSeriesData(me.prevData);

      const currentStats = calculateStats(seriesData);
      const prevStats = calculateStats(prevSeriesData);

      me.mean = currentStats.mean;
      me.median = currentStats.median;
      me.mode = currentStats.mode;
      me.std = currentStats.stdDev;
      me.skewness = currentStats.skewness;
      me.kurtosis = currentStats.kurtosis;
      me.bottom = currentStats.bottom;
      me.top = currentStats.top;
      me.lowerBound = currentStats.lowerBound;
      me.upperBound = currentStats.upperBound;

      me.prevMedian = prevStats.median;
      me.prevMean = prevStats.mean;
      me.prevMode = prevStats.mode;
      me.prevStd = prevStats.stdDev;
      me.prevSkewness = prevStats.skewness;
      me.prevKurtosis = prevStats.kurtosis;
      me.prevBottom = prevStats.bottom;
      me.prevTop = prevStats.top;
      me.prevLowerBound = prevStats.lowerBound;
      me.prevUpperBound = prevStats.upperBound;

      const expandedData = seriesData.filter(
        (value) => value >= me.bottom && value <= me.top
      );
      me.expandendData = expandedData;
      const binWidth = me.bins;
      const binCount = Math.ceil(
        (Math.max(...expandedData) - Math.min(...expandedData)) / binWidth
      );
      let bins = Array(binCount).fill(0);
      const binEdges = [];
      for (let i = 0; i < binCount; i++) {
        binEdges.push(Math.min(...expandedData) + i * binWidth);
      }
      expandedData.forEach((value) => {
        const binIndex = Math.floor(
          (value - Math.min(...expandedData)) / binWidth
        );
        bins[binIndex]++;
      });
      bins = bins.filter((bin) => !isNaN(bin));
      let xAxisData = binEdges.map((edge) => edge + binWidth / 2);
      me.seriesData = bins;

      const prevExpandedData = prevSeriesData.filter(
        (value) => value >= me.prevBottom && value <= me.prevTop
      );
      me.prevExpandendData = prevExpandedData;
      const prevBinCount = Math.ceil(
        (Math.max(...prevExpandedData) - Math.min(...prevExpandedData)) /
          binWidth
      );
      let prevBins = Array(prevBinCount).fill(0);
      const prevBinEdges = [];
      for (let i = 0; i < prevBinCount; i++) {
        prevBinEdges.push(Math.min(...prevExpandedData) + i * binWidth);
      }
      prevExpandedData.forEach((value) => {
        const binIndex = Math.floor(
          (value - Math.min(...prevExpandedData)) / binWidth
        );
        prevBins[binIndex]++;
      });
      prevBins = prevBins.filter((bin) => !isNaN(bin));
      const prevXAxisData = prevBinEdges.map((edge) => edge + binWidth / 2);
      me.prevSeriesData = prevBins;

      const createKDEpoints = (data, series, min, max) => {
        const bandwidth = 10;
        const kde = ss.kernelDensityEstimation(
          data,
          ss.kernelGaussian,
          bandwidth
        );
        const kdePoints = 200;
        const kdeData = [];
        const xMin = min || Math.min(...data);
        const xMax = max || Math.max(...data);
        const step = (xMax - xMin) / kdePoints;
        for (let x = xMin; x <= xMax; x += step) {
          kdeData.push([x, kde(x)]);
        }
        // Ajustar el escalado de KDE
        const maxCount = Math.max(...series);
        const kdeYValues = kdeData.map((point) => point[1]);
        const kdeMax = Math.max(...kdeYValues);
        const scale = maxCount / kdeMax;
        return kdeData.map((point) => [point[0], point[1] * scale]);
      };

      const kdeData = createKDEpoints(seriesData, bins, me.bottom, me.top);
      const prevKdeData = createKDEpoints(
        prevSeriesData,
        prevBins,
        me.prevBottom,
        me.prevTop
      );
      const stds = [];
      const deviations = [-2, -1, 1, 2]; // Desviaciones estándar a considerar
      for (const deviation of deviations) {
        const bound = me.median + deviation * me.std;
        const line = {
          type: "line",
          markLine: {
            symbol: "none",
            label: {
              show: true,
              position: "end",
              formatter: `${deviation}*std ({c})`,
            },
            silent: true,
            data: [
              {
                xAxis: bound,
              },
            ],
            lineStyle: {
              color: "rgba(0, 0, 255, 0.7)",
            },
          },
        };
        stds.push(line);
      }
      // Agregar la línea de la mediana

      stds.push({
        type: "line",
        markLine: {
          silent: true,
          symbol: "none",
          label: {
            show: true,
            position: "end",
            formatter: "Mediana ({c})",
          },
          data: [
            {
              xAxis: me.median,
            },
          ],
          lineStyle: {
            color: "rgba(0, 0, 255, 0.7)",
          },
        },
      });
      // Agregar la línea de la media
      stds.push({
        type: "line",
        markLine: {
          silent: true,
          symbol: "none",
          label: {
            show: true,
            position: "middle",
            formatter: "Media ({c})",
          },
          data: [
            {
              xAxis: me.mean,
            },
          ],
          lineStyle: {
            color: "rgba(255,0,0,0.7)",
          },
        },
      });
      me.option = {
        xAxis: {
          type: "value",
          min: me.showPrev ? Math.min(me.bottom, me.prevBottom) : me.bottom,
          max: me.showPrev ? Math.max(me.top, me.prevTop) : me.top,
        },
        yAxis: {
          type: "value",
        },
        legend: {
          data: ["Current Histogram", "Previous Histogram"],
          selected: {
            "Histograma Previo": me.showPrev,
            "KDE Previo": me.showPrev,
          },
        },
        series: [
          {
            name: "Histograma Previo",
            show: me.showPrev,
            data: prevXAxisData.map((x, i) => [x, me.prevSeriesData[i]]),
            type: "bar",
            barWidth: "100%",
            itemStyle: {
              color: "rgba(255,212,0,0.7)",
            },
          },
          {
            name: "KDE Previo",
            data: prevKdeData,
            type: "line",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 2,
              color: "orange",
            },
          },
          {
            name: "Histograma",
            data: xAxisData.map((x, i) => [x, me.seriesData[i]]),
            type: "bar",
            barWidth: "100%",
            itemStyle: {
              color: "rgba(0, 0, 255, 0.7)",
            },
          },
          {
            name: "KDE",
            data: kdeData,
            type: "line",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 2,
              color: "orange",
            },
          },
          ...stds,
        ],
      };

      me.isLoading = false;
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 400px;
}

.statistics-table {
  width: 100%;
  border-collapse: collapse;
}

.statistics-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.statistics-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.statistics-table tr:hover {
  background-color: #ddd;
}
.control-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 1em;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.control-panel > div {
  flex: 1 0 20%; /* Esto hará que cada div ocupe al menos el 20% del ancho total, pero puede crecer si hay espacio disponible */
  margin: 0.5em;
}

input[type="number"],
input[type="date"],
select {
  display: block;
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="checkbox"] {
  margin-right: 0.5em;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5em;
}
</style>
