<template>
  <ul class="button-list">
    <li>
      <button @click="downloadExcel('https://api.cambiocuba.money/api/v1/download-excel/all-days-stats')">Descargar TRMI</button>
    </li>
      <li>
      <button @click="downloadExcel('https://api.cambiocuba.money/api/v1/download-excel/all-days-crypto-stats')">Descargar TRMI CRYPTO</button>
    </li>
    <li>
      <button @click="downloadExcel('https://api.cambiocuba.money/api/v1/download-excel/trmi-vip')">Descargar TRMI/VIP</button>
    </li>
    <li>
      <button @click="downloadExcel('https://api.cambiocuba.money/api/v1/download-excel/ma20')">Descargar MA20</button>
    </li>
    <li>
      <button @click="downloadExcel('https://api.cambiocuba.money/api/v1/download-excel/buying-selling-volume')">Descargar Volumen</button>
    </li>
    <li>
      <button @click="downloadExcel('https://api.cambiocuba.money/api/v1/download-excel/all-messages-data')">Descargar Mensajes</button>
    </li>
      <li>
      <button @click="downloadExcel('https://api.cambiocuba.money/api/v1/download-excel/median-by-hour')">Descargar Mediana por hora</button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Excels",
  methods: {
    downloadExcel(url) {
      window.open(url, '_blank')
    }
  }
};
</script>

<style scoped>
.button-list {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 30px 0;
}

.button-list li {
  margin: 0 10px;
}

.button-list li button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-list li button:hover {
  background-color: #3e8e41;
}
</style>
