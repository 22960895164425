<template>
  <div class="home">
    <div style="width: 95%; height: 400px; margin: 30px auto 0 auto">
      <div class="title-chart">
        {{ title }}
      </div>

      <chart-container :is-loading="isLoading">
        <v-chart
          ref="chart"
          v-if="option && !isLoading"
          class="chart"
          :option="option"
          @click="onChartClick"
        >
        </v-chart>
      </chart-container>
      <date-range-selector-component
        :on-change="onDateRangeChange"
      ></date-range-selector-component>
    </div>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <div v-if="date_from_nu" class="date-container">
      <div class="title-bonito">Ignorar usuarios desde: {{ date_from_nu }}</div>
      <div style="margin-bottom: 30px">
        <litepie-datepicker
          style="max-width: 300px"
          i18n="es"
          as-single
          :formatter="formatter"
          v-model="dateValues"
        ></litepie-datepicker>
      </div>
      <div v-show="isLoadingOld" class="loading" ></div>

    </div>
    <br />
    <div class="date-container2" >
      <div
        v-for="currency in filteredCurrencies"
        :key="currency.name"
        class="currency-row"
      >
        <div class="currency-name">{{ currency.name }}</div>
        <div class="currency-data">
          <div class="data-column">
            <h3>Todos los usuarios</h3>
            <p>Avg: {{ currency.allUsers.avg }}</p>
            <p>Min: {{ currency.allUsers.min }}</p>
            <p>Max: {{ currency.allUsers.max }}</p>
            <p>Mediana: {{ currency.allUsers.median }}</p>
            <p>Ofertas: {{ currency.allUsers.count_messages }}</p>
          </div>
          <div class="data-column">
            <h3>Con usuarios antiguos</h3>
            <p>Avg: {{ currency.oldUsers.avg }}</p>
            <p>Min: {{ currency.oldUsers.min }}</p>
            <p>Max: {{ currency.oldUsers.max }}</p>
            <p>Mediana: {{ currency.oldUsers.median }}</p>
            <p>Ofertas: {{ currency.oldUsers.count_messages }}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {
  getUserCountByDay,
  getTRMIWithoutNewUsersByDay,
  getTRMIByDay,
} from "@/utils/apiUtils";
import DateRangeSelectorComponent from "@/components/DateRangeSelectorComponent.vue";
import ChartContainer from "@/components/ChartContainer.vue";
import moment from "moment";

export default {
  components: { ChartContainer, DateRangeSelectorComponent },
  name: "DayUsersLineChart",
  props: {
    title: String,
  },
  mounted() {
    this.getOptions(7);
  },
  computed: {
    filteredCurrencies() {
      const allowedCurrencies = ['ECU', 'USD', 'MLC'];
      return this.currencies.filter(currency => allowedCurrencies.includes(currency.name));
    }
  },
  watch: {
    dateValues: function (val) {
      console.log("date value", val);
      let { date_from, date_to } = this.getDates(val);
      console.log(date_from, date_to);
      let newCurrencies = []; // Crea una copia de me.currencies
      this.isLoadingOld = true;
      getTRMIWithoutNewUsersByDay(
        `${this.date_from_nu} 00:00:00`,
        date_from,
        date_to
      )
        .then((res) => {
          for (let [curr, stats] of Object.entries(res.data.statistics)) {
            let currency = curr.split(".")[0];
            newCurrencies.push({
              name: currency,
              oldUsers: {
                avg: stats.avg,
                min: stats.min,
                max: stats.max,
                median: stats.median,
                count_messages: stats.count_messages,
              },
            });
          }
          getTRMIByDay(date_from, date_to)
            .then((res) => {
              for (let [curr, stats] of Object.entries(res.data.statistics)) {
                let currencyIndex = newCurrencies.findIndex(
                  (currency) => currency.name === curr
                );
                if (currencyIndex !== -1) {
                  newCurrencies[currencyIndex].allUsers = {
                    avg: stats.avg,
                    min: stats.min,
                    max: stats.max,
                    median: stats.median,
                    count_messages: stats.count_messages,
                  };
                }
              }
              console.log(newCurrencies)
              this.currencies = newCurrencies;
              this.isLoadingOld = false;
            })
            .catch((e) => {
              console.log(e);
              this.isLoadingOld = false;
            });
        })
        .catch((e) => {
          console.log(e);
          this.isLoadingOld = false;
        });
    },
  },
  data() {
    return {
      currencies: [
        // Repite la estructura para USD y MLC
      ],
      isLoadingOld: false,
      isLoading: false,
      date_from_nu: "", // Default today
      days: 7,
      formatter: {
        date: "YYYY-MM-DD",
        month: "MMM",
      },
      dateValues: "" /* Range entered on the input */,
      option: null,
    };
  },
  methods: {
    getDates(inputDate) {
      // Parsea la fecha de entrada
      let date = moment(inputDate, "YYYY-MM-DD");

      // Crea la fecha 'date_to' añadiendo la hora 08:00:00
      let date_to = date
        .clone()
        .hour(8)
        .minute(0)
        .second(0)
        .format("YYYY-MM-DD HH:mm:ss");

      // Crea la fecha 'date_from' restando un día y añadiendo la hora 08:00:00
      let date_from = date
        .clone()
        .subtract(1, "days")
        .hour(8)
        .minute(0)
        .second(0)
        .format("YYYY-MM-DD HH:mm:ss");

      return { date_from, date_to };
    },
    onDateRangeChange(days, dateValue) {
      console.log("on date range change", days, " ", dateValue);
      this.days = days;
      this.dateValue = dateValue ? dateValue : [];
      this.getOptions(days, dateValue);
    },
    onChartClick(params) {
      console.log(params.name);
      this.date_from_nu = params.name;
    },

    getOptions(days, dates) {
      const me = this;
      me.days = days;
      me.isLoading = true;

      // Fetch user count by day
      getUserCountByDay(days, dates)
        .then((res) => {
          let data = res.data;
          // Process dates for x-axis
          const dateList = data.map((el) => {
            return el._id;
          });
          //
          // Process user count for y-axis
          const userCounts = data.map((el) => el.user_count);
          me.option = {
            tooltip: {
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              data: dateList,
              axisLabel: {
                fontFamily: "Roboto-Medium",
              },
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                silent: false,
                triggerEvent: true,
                // Ajusta el tamaño de los símbolos (puntos en el gráfico)
                symbolSize: 10,
                data: userCounts,
                type: "line",
                showSymbol: true,
                smooth: false,
                markPoint: {
                  data: [{ type: "max", name: "Max" }],
                },
                name: "Users",
              },
            ],
          };
          me.isLoading = false;
        })
        .catch((e) => {
          me.isLoading = false;
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.date-container {
  display: flex;
  flex-direction: column; /* Ajusta la dirección del contenedor flex a columna */
  justify-content: center;
  align-items: center;
}
.date-container2 {
  width: 70%;
  margin: 0 auto;
}

.title-bonito {
  font-size: 24px; /* Ajusta el tamaño de la fuente */
  color: #333; /* Ajusta el color del texto */
  text-align: center; /* Centra el texto */
  margin-top: 20px; /* Añade un margen superior */
  font-weight: bold; /* Hace que el texto sea negrita */
}
.currency-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Añade un borde inferior a cada fila */
  background-color: #f9f9f9; /* Añade un color de fondo a cada fila */
}

.currency-row:nth-child(even) {
  background-color: #e9e9e9; /* Añade un color de fondo alterno a las filas pares */
}

.currency-name {
  width: 20%;
  font-weight: bold;
  order: -1;
  text-align: center; /* Centra el texto en las celdas */
}

.currency-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.data-column {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ddd; /* Añade un borde derecho a cada columna */
  padding: 10px; /* Añade un padding a cada celda */
}
.loading {
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 12px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
