import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import PlatformsView from "../views/PlatformsView.vue";
import Chart from "../views/Chart.vue";
import ExcelsView from "../views/Excels.vue";
import ChartCandlestick from "../views/ChartCandlestick.vue";
import ChartBuySell from "../views/ChartBuySell.vue";
import ChartHoursMsg from "../views/ChartHoursMsg.vue";
import CryptoRatesView from "../views/CryptoRatesView";
import CryptoLineChartView from "../views/CryptoLineChartView";
import CryptoBuySellChartView from "../views/CryptoBuySellChartView";
import CryptoCandlestickChartView from "../views/CryptoCandlestickChartView";
import CryptoRealRatesView from "../views/CryptoRealRatesView";
import CryptoRealRatesViewApp from "../views/CryptoRealRatesViewApp";
import CryptoRatesViewApp from "../views/CryptoRatesViewApp";
import CalcViews from "../views/CalcViews";
import NewTablesView from "../views/NewTablesView";
import PorkTables from "../views/PorkTablesView";
import ChartNewUsers from "@/views/ChartNewUsers.vue";
import ChartHistogram from "@/views/ChartHistogram.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/crypto-rates",
    name: "CryptoView",
    component: CryptoRatesView,
  },
  {
    path: "/real-crypto-rates",
    name: "CryptoView2",
    component: CryptoRealRatesView,
  },
  {
    path: "/real-crypto-rates-app",
    name: "CryptoView3",
    component: CryptoRealRatesViewApp,
  },
  {
    path: "/crypto-rates-app",
    name: "CryptoView4",
    component: CryptoRatesViewApp,
  },
  {
    path: "/calculator/:type",
    name: "Calculator",
    component: CalcViews,
  },
  {
    path: "/chart",
    name: "Chart",
    component: Chart,
  },
  {
    path: "/chart-candlestick",
    name: "Chart2",
    component: ChartCandlestick,
  },
  {
    path: "/chart-buy-sell",
    name: "ChartBuySell",
    component: ChartBuySell,
  },
  {
    path: "/chart-hours-msg",
    name: "ChartHoursMsg",
    component: ChartHoursMsg,
  },
  {
    path: "/crypto-chart-buy-sell",
    name: "CryptoLineChartView",
    component: CryptoBuySellChartView,
  },
  {
    path: "/crypto-line-chart",
    name: "CryptoChart",
    component: CryptoLineChartView,
  },
  {
    path: "/crypto-chart-candlestick",
    name: "CryptoChartCandlestick",
    component: CryptoCandlestickChartView,
  },
  {
    path: "/reports",
    name: "Excels",
    component: ExcelsView,
  },
  {
    path: "/platforms",
    name: "Platforms",
    component: PlatformsView,
  },
  {
    path: "/new-tables",
    name: "NewTables",
    component: NewTablesView,
  },
  {
    path: "/pork-tables",
    name: "PorkTables",
    component: PorkTables,
  },
  {
    path: "/new-users",
    name: "NewUsers",
    component: ChartNewUsers,
  },
  {
    path: "/histogram",
    name: "Histogram",
    component: ChartHistogram,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
