<template>
  <div class="radio-group"> <!-- Aquí añadimos el div contenedor -->
    <div style="text-align: end">
      <input
        :value="true"
        type="radio"
        v-model="real"
        id="isreal"
        :checked="modelValue"
        @change="$emit('update:modelValue', true)"
      /><label for="isreal"> Unidades de cripto </label>
    </div>

    <div style="text-align: start">
      <input
        :value="false"
        type="radio"
        v-model="real"
        id="notreal"
        @change="$emit('update:modelValue', false)"
      /><label for="notreal"> 1 USD en cripto </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValueTypeRadioGroup",
  props: {
    modelValue: Boolean,
    disabled: Boolean
  },
  emits: ["update:modelValue"]
};
</script>

<style scoped>
.radio-group {
  flex-direction: row !important; /* Anula flex-col */
  align-items: flex-start !important; /* Anula items-start */
  padding-top: 25px; /* Espacio arriba */
  padding-bottom: 25px; /* Espacio abajo */
}
.radio-group > div {
  flex: 0 1 50%; /* Ocupa exactamente el 50% del espacio */
  text-align: center;
  box-sizing: border-box; /* Asegura que el padding y border no aumenten el tamaño del div */
  padding: 0 5px; /* Espacio adicional para evitar que los elementos estén demasiado juntos */
}
</style>
