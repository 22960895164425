function normalQuantile(p, mu, sigma) {
  var q, r, val;
  if (sigma < 0) return -1;
  if (sigma == 0) return mu;

  q = p - 0.5;

  if (0.075 <= p && p <= 0.925) {
    r = 0.180625 - q * q;
    val =
      (q *
        (((((((r * 2509.0809287301226727 + 33430.575583588128105) * r +
          67265.770927008700853) *
          r +
          45921.953931549871457) *
          r +
          13731.693765509461125) *
          r +
          1971.5909503065514427) *
          r +
          133.14166789178437745) *
          r +
          3.387132872796366608)) /
      (((((((r * 5226.495278852854561 + 28729.085735721942674) * r +
        39307.89580009271061) *
        r +
        21213.794301586595867) *
        r +
        5394.1960214247511077) *
        r +
        687.1870074920579083) *
        r +
        42.313330701600911252) *
        r +
        1);
  } else {
    if (q > 0) r = 1 - p;
    else r = p;

    r = Math.sqrt(-Math.log(r));

    if (r <= 5) {
      r += -1.6;
      val =
        (((((((r * 7.7454501427834140764e-4 + 0.0227238449892691845833) * r +
          0.24178072517745061177) *
          r +
          1.27045825245236838258) *
          r +
          3.64784832476320460504) *
          r +
          5.7694972214606914055) *
          r +
          4.6303378461565452959) *
          r +
          1.42343711074968357734) /
        (((((((r * 1.05075007164441684324e-9 + 5.475938084995344946e-4) * r +
          0.0151986665636164571966) *
          r +
          0.14810397642748007459) *
          r +
          0.68976733498510000455) *
          r +
          1.6763848301838038494) *
          r +
          2.05319162663775882187) *
          r +
          1);
    } else {
      r += -5;
      val =
        (((((((r * 2.01033439929228813265e-7 + 2.71155556874348757815e-5) * r +
          0.0012426609473880784386) *
          r +
          0.026532189526576123093) *
          r +
          0.29656057182850489123) *
          r +
          1.7848265399172913358) *
          r +
          5.4637849111641143699) *
          r +
          6.6579046435011037772) /
        (((((((r * 2.04426310338993978564e-15 + 1.4215117583164458887e-7) * r +
          1.8463183175100546818e-5) *
          r +
          7.868691311456132591e-4) *
          r +
          0.0148753612908506148525) *
          r +
          0.13692988092273580531) *
          r +
          0.59983220655588793769) *
          r +
          1);
    }

    if (q < 0.0) val = -val;
  }
  return mu + sigma * val;
}

function sign(x) {
  return x > 0 ? 1 : x < 0 ? -1 : 0;
}

function ShapiroWilkW(x) {
  function poly(cc, nord, x) {
    var ret_val = cc[0];
    if (nord > 1) {
      var p = x * cc[nord - 1];
      for (var j = nord - 2; j > 0; j--) {
        p = (p + cc[j]) * x;
      }
      ret_val += p;
    }
    return ret_val;
  }

  x = x.slice().sort(function (a, b) {
    return a - b;
  });

  var n = x.length;
  if (n < 3) return undefined;
  var nn2 = Math.floor(n / 2);
  var a = new Array(nn2 + 1); // 1-based indexing

  var small = 1e-19;
  var c1 = [0, 0.221157, -0.147981, -2.07119, 4.434685, -2.706056];
  var c2 = [0, 0.042981, -0.293762, -1.752461, 5.682633, -3.582633];

  var i, j, i1;

  var ssassx, summ2, ssumm2, range;
  var a1, a2, an, sa, xi, sx, xx, w1;
  var fac, asa, an25, ssa, sax, rsn, ssx, xsx;

  an = n;

  if (n === 3) {
    a[1] = 0.70710678; // sqrt(1/2)
  } else {
    an25 = an + 0.25;
    summ2 = 0.0;
    for (i = 1; i <= nn2; i++) {
      a[i] = normalQuantile((i - 0.375) / an25, 0, 1);
      summ2 += a[i] * a[i];
    }
    summ2 *= 2;
    ssumm2 = Math.sqrt(summ2);
    rsn = 1 / Math.sqrt(an);
    a1 = poly(c1, 6, rsn) - a[1] / ssumm2;

    if (n > 5) {
      i1 = 3;
      a2 = -a[2] / ssumm2 + poly(c2, 6, rsn);
      fac = Math.sqrt(
        (summ2 - 2 * (a[1] * a[1]) - 2 * (a[2] * a[2])) /
          (1 - 2 * (a1 * a1) - 2 * (a2 * a2))
      );
      a[2] = a2;
    } else {
      i1 = 2;
      fac = Math.sqrt((summ2 - 2 * (a[1] * a[1])) / (1 - 2 * (a1 * a1)));
    }
    a[1] = a1;
    for (i = i1; i <= nn2; i++) {
      a[i] /= -fac;
    }
  }

  range = x[n - 1] - x[0];
  if (range < small) {
    console.log("range is too small!");
    return undefined;
  }

  xx = x[0] / range;
  sx = xx;
  sa = -a[1];
  for (i = 1, j = n - 1; i < n; j--) {
    xi = x[i] / range;
    if (xx - xi > small) {
      console.log("xx - xi is too big.", xx - xi);
      return undefined;
    }
    sx += xi;
    i++;
    if (i !== j) sa += sign(i - j) * a[Math.min(i, j)];
    xx = xi;
  }
  if (n > 5000) {
    console.log("n is too big!");
    return undefined;
  }

  sa /= n;
  sx /= n;
  ssa = ssx = sax = 0;
  for (i = 0, j = n - 1; i < n; i++, j--) {
    if (i !== j) asa = sign(i - j) * a[1 + Math.min(i, j)] - sa;
    else asa = -sa;
    xsx = x[i] / range - sx;
    ssa += asa * asa;
    ssx += xsx * xsx;
    sax += asa * xsx;
  }

  ssassx = Math.sqrt(ssa * ssx);
  w1 = ((ssassx - sax) * (ssassx + sax)) / (ssa * ssx);
  var w = 1 - w1;

  return w;
}

function ShapiroWilk(x) {
  var W = ShapiroWilkW(x);
  var n = x.length;
  if (n < 3 || n > 5000) {
    console.log("El tamaño de la muestra debe estar entre 3 y 5000.");
    return { W: W, pValue: undefined };
  }

  let z;
  if (n <= 11) {
    const mu = polyMu(n);
    const sigma = polySigma(n);
    z = (Math.log(W) - mu) / sigma;
  } else {
    const ln_n = Math.log(n);
    const mu =
      -1.5861 -
      0.31082 * ln_n -
      0.083751 * Math.pow(ln_n, 2) +
      0.0038915 * Math.pow(ln_n, 3);
    const sigma = Math.exp(
      -0.4803 - 0.082676 * ln_n + 0.0030302 * Math.pow(ln_n, 2)
    );
    z = (Math.log(1 - W) - mu) / sigma;
  }

  var pValue = 1 - normalCDF(z);

  // Manejo de valores p muy pequeños
  var minPValue = 1e-16;
  if (pValue < minPValue) {
    pValue = "< " + minPValue;
  }

  return { W: W, pValue: pValue };
}

function polyMu(n) {
  // Coeficientes basados en Royston (1992)
  return (
    -0.0006714 * Math.pow(n, 3) +
    0.025054 * Math.pow(n, 2) -
    0.39978 * n +
    0.544
  );
}

function polySigma(n) {
  return Math.exp(
    -0.0020322 * Math.pow(n, 3) +
      0.062767 * Math.pow(n, 2) -
      0.77857 * n +
      1.3822
  );
}

function normalCDF(x) {
  if (x >= 0) {
    return 0.5 * erfc(-x / Math.sqrt(2));
  } else {
    var p = 0.5 * erfc(-x / Math.sqrt(2));
    return p > Number.MIN_VALUE ? p : Number.MIN_VALUE;
  }
}

function erfc(x) {
  var z = Math.abs(x);
  var t = 1 / (1 + z / 2);
  var r =
    t *
    Math.exp(
      -z * z -
        1.26551223 +
        t *
          (1.00002368 +
            t *
              (0.37409196 +
                t *
                  (0.09678418 +
                    t *
                      (-0.18628806 +
                        t *
                          (0.27886807 +
                            t *
                              (-1.13520398 +
                                t *
                                  (1.48851587 +
                                    t * (-0.82215223 + t * 0.17087277))))))))
    );
  return x >= 0 ? r : 2 - r;
}

export default ShapiroWilk;
