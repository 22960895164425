<template>
  <div class="home">
    <div style="width: 95%; height: 400px; margin: 30px auto 0 auto">
      <div class="title-chart">
        {{ title }}
      </div>

      <chart-container :is-loading="isLoading">
        <v-chart v-if="option && !isLoading" class="chart" :option="option">
        </v-chart>
      </chart-container>
      <date-range-selector-component
        :on-change="onDateRangeChange"
      ></date-range-selector-component>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DateRangeSelectorComponent from "./DateRangeSelectorComponent";
import { getRatesByDateRange } from "../utils/apiUtils";
import { getLegend } from "../utils/curUtils";
import {fillBlankSpaces, getDates} from "../utils/data";
import ChartContainer from "../components/ChartContainer";

export default {
  name: "CurLineChart",
  components: { DateRangeSelectorComponent, ChartContainer },
  props: {
    title: String,
    currencies: Array,
  },
  mounted() {
    this.getOptions(7);
  },
  data() {
    return {
      isLoading: false,
      days: 7,
      formatter: {
        date: "YYYY-MM-DD",
        month: "MMM",
      },
      dateValue: [] /* Range entered on the input */,
      option: null,
    };
  },
  methods: {
    onDateRangeChange(days, dateValue) {
      console.log("on date range change", days, ' ', dateValue)
      this.days = days;
      this.dateValue = dateValue ? dateValue : [];
      this.getOptions(days, dateValue);
    },
    getOptions(days, dates) {
      const me = this;
      me.days = days;

      console.log(this.currencies);
      me.isLoading = true;
      Promise.all(
        me.currencies.map((el) => {
          return getRatesByDateRange(el, days, dates);
        })
      )
        .then((res) => {
          let data = res.map((resp) => resp.data); // Each data [{_id: 2021-12-26, median: 74.5200000001, min: 72: max: 84, last: {value: 74.64, date: '2021-12-27 05:05:03, first: {value: 74.3, date: '2021-12-26 14:23:00, avg: 75.87, count_values: 5}}}];
          console.log("data", data);
          const dateList = getDates(data, days,dates);
          let minNumber = Math.min(...data.flatMap((dataset) => dataset.map(item => item.median)));
          minNumber = minNumber - 10;
          data = data.map((ratesByDate) => {
            const cleanArr = dateList.map((el) => ({ id: el, value: null }));
            for (const it of ratesByDate) {
              const i = cleanArr.findIndex((el) => it._id === el.id);
              if (i > -1) cleanArr[i].value = it.median;
            }
            return cleanArr.map((el) => el.value);
          });

          // Fill blank spaces
          data = data.map((ratesByDate) => fillBlankSpaces(ratesByDate));

          me.option = {
            grid: {
              bottom: 30,
            },
            color: [
              "#1636c1",
              "#d2011a",
              "#05ce7c",
              "#f99b2b",
              "#182360",
              "#1b5b20",
              "#6b05ce",
              "#cccc06",
            ],
            // Make gradient line here
            legend: {
              data: me.currencies.map((cur) => getLegend(cur)),
              show: true,
              top: 10,
            },
            tooltip: {
              trigger: "axis",
              // formatter: '{b} | {c} CUP'
            },
            xAxis: [
              {
                data: dateList,
                axisLabel: {
                  fontFamily: "Roboto-Medium",
                },
              },
              // {
              //   data: dateList,
              //   gridIndex: 1,
              // },
            ],
            yAxis: [
              {
                min: minNumber,
                axisLabel: {
                  fontFamily: "Roboto-Medium",
                },
              },
              // {
              //   gridIndex: 1,
              // },
            ],
            series: data.map((el, i) => ({
              type: "line",
              showSymbol: true,
              smooth: false,
              // areaStyle: {},
              markPoint:
                // ["EUR", "USD", "MLC"].includes(currencies[i])
                // ?
                {
                  data: [
                    { type: "max", name: "Max" },
                    // { type: "min", name: "Min" },
                  ],
                },
              // : {},
              name: getLegend(me.currencies[i]),
              data: el,
            })),
          };
          me.isLoading = false;
        })
        .catch((e) => {
          me.isLoading = false;
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.title-chart {
  font-family: "RobotoSlab-Bold", sans-serif;
}
.selectrange span {
  cursor: pointer;
  font-family: "Roboto-Medium", sans-serif;
  display: inline-block;
}
</style>
