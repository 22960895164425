<template>
  <cur-clandestik-chart
    :title="title"
    :currencies="currencies"
    :initialSelectedCur="initialSelectedCur"
  />
</template>

<script>
// @ is an alias to /src
import CurClandestikChart from "../components/CurClandestikChart";

export default {
  name: "ChartCandlestick",
  components: { CurClandestikChart },
  data() {
    return {
      title: "Evolución de la tasa informal del",
      currencies: ["MLC", "USD", "ECU"],
      initialSelectedCur: "MLC",
    };
  },
};
</script>

<style>
.box-title {
  font-size: 16px;
  letter-spacing: 0.5px;
}

.box-rates-body {
  padding: 20px 10%;
  font-size: 18px;
}
.text-upper {
  text-transform: uppercase;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-family: "Roboto-Medium";
  font-style: italic;
}

.mlc-quote {
  margin-top: -5px !important;
  font-family: RobotoSlab-Regular;
  font-weight: 100;
  font-size: 7.5px;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.body-row {
  border-bottom: 1px white solid;
}
.currency-box {
  border-right: 1px white solid;
}

.currency-box,
.value-box {
  padding-top: 3px;
  padding-bottom: 3px;
}

.space-2 {
  margin-right: 10px;
}
.space-4 {
  margin-right: 20px;
}

.down-icon {
  background-image: url(../assets/TriangleArrow-Down.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.up-icon {
  background-image: url(../assets/up-arrow.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.flag-icon-mlc {
  background-image: url(../assets/card.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.negative-diff {
  color: #1f4a7f;
}
.positive-diff {
  color: #ec1d26;
}
.selectrange span {
  cursor: pointer;
  font-family: "Roboto-Medium";
  display: inline-block;
}

.selected-opt {
  font-weight: bolder;
  font-size: 20px;
}
.title-chart {
  font-family: "RobotoSlab-Bold";
}
</style>
