<template>
  <div class="home">
    <div style="width: 95%; height: 400px; margin: 30px auto 0 auto">
      <div class="title-chart">
        {{ title }}
      </div>

      <chart-container :is-loading="isLoading">
        <v-chart v-if="option && !isLoading" class="chart" :option="option">
        </v-chart>
      </chart-container>
      <date-range-selector-component
        :on-change="onDateRangeChange"
        :show-periods="false"
      ></date-range-selector-component>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getLegend } from "../utils/curUtils";
// import { fillBlankSpaces, getDates } from "../utils/data";
import ChartContainer from "../components/ChartContainer";
import { getMsgHoursByDay } from "@/utils/apiUtils";
import DateRangeSelectorComponent from "@/components/DateRangeSelectorComponent.vue";

export default {
  name: "HourLineChart",
  components: { DateRangeSelectorComponent, ChartContainer },
  props: {
    title: String,
    currencies: Array,
  },
  mounted() {
    this.getOptions(2);
  },
  data() {
    return {
      isLoading: false,
      days: 2,
      dateValue: new Date(),
      option: null,
    };
  },
  methods: {
    onDateRangeChange(days, dateValue) {
      console.log("on date range change", days, " ", dateValue);
      this.days = days;
      this.dateValue = dateValue ? dateValue : [];
      this.getOptions(days, dateValue);
    },
    getOptions(days, dates) {
      const me = this;
      me.days = days;

      console.log(this.currencies);
      me.isLoading = true;
      Promise.all(
        me.currencies.map((el) => {
          return getMsgHoursByDay(el, days, dates);
        })
      )
        .then((res) => {
          let data = res.map((resp) => resp.data); // Each data [{_id: {date: "2023-05-15", hour: 7}, totalMessages: 1, median: 180}];
          const dateList = data[0].map((el) => {
            const fecha = new Date(el._id.date);
            fecha.setHours(el._id.hour);
            const month = fecha.getMonth() + 1; // los meses en JavaScript empiezan en 0
            const day = fecha.getDate();
            const hour = fecha.getHours();

            // Asegúrate de que los valores de un solo dígito tengan un cero delante
            const formattedMonth = month < 10 ? "0" + month : month;
            const formattedDay = day < 10 ? "0" + day : day;
            const formattedHour = hour < 10 ? "0" + hour : hour;

            const formattedDate = `${formattedDay}/${formattedMonth} ${formattedHour}h`;
            return formattedDate;
          });
          me.option = {
            grid: {
              bottom: 30,
            },
            color: [
              "#1636c1",
              "#d2011a",
              "#05ce7c",
              "#f99b2b",
              "#182360",
              "#1b5b20",
              "#6b05ce",
              "#cccc06",
            ],
            // Make gradient line here
            legend: {
              data: me.currencies.map((cur) => getLegend(cur)),
              show: true,
              top: 10,
            },
            tooltip: {
              trigger: "axis",
              // formatter: '{b} | {c} CUP'
            },
            xAxis: [
              {
                data: dateList,
                axisLabel: {
                  fontFamily: "Roboto-Medium",
                },
              },
              // {
              //   data: dateList,
              //   gridIndex: 1,
              // },
            ],
            yAxis: [
              {
                // min: 40,
                axisLabel: {
                  fontFamily: "Roboto-Medium",
                },
              },
              // {
              //   gridIndex: 1,
              // },
            ],
            series: data.map((el, i) => ({
              type: "line",
              showSymbol: true,
              smooth: false,
              // areaStyle: {},
              markPoint: {
                data: [
                  { type: "max", name: "Max" }
                ],
              },
              name: getLegend(me.currencies[i]),
              data: el.map((el) => el.totalMessages),
            })),
          };
          me.isLoading = false;
        })
        .catch((e) => {
          me.isLoading = false;
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.title-chart {
  font-family: "RobotoSlab-Bold", sans-serif;
}
.selectrange span {
  cursor: pointer;
  font-family: "Roboto-Medium", sans-serif;
  display: inline-block;
}
</style>
