const axios = require("axios");

const instance = axios.create({
  baseURL: "https://api.cambiocuba.money/api/v2/",
  timeout: 50000,
});

exports.get = function get(url, config) {
  return instance.get(url, config);
};
