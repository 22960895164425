export function getCurName(curShort) {
  curShort = curShort.toUpperCase();
  switch (curShort) {
    case "BTC":
      return "BITCOIN";
    case "BTC2":
      return "USD";
    case "USDT_ERC20":
      return "ERC20";
    case "USDT_TRC20":
      return "TRC20";
    case "BNB":
      return "BINANCE";
    case "BNB2":
      return "USD";
    case "TRX":
      return "TRON";
    case "ECU":
      return "EUR";
    default:
      return curShort;
  }
}

export function getLegend(curShort) {
  switch (curShort) {
    case "USDT_ERC20":
      return "ERC20 (USDT)";
    case "USDT_TRC20":
      return "USDT (TRC20)";
    default:
      return getCurName(curShort);
  }
}
