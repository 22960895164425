import moment from "moment";

export function findMin(datesArray) {
  let min = moment(datesArray[0]);
  // let max = moment(datesArray[0]);

  for (const date of datesArray) {
    let _date = moment(date);
    if (_date < min) {
      min = _date;
    }
    // if (_date > max) {
    //   max = _date;
    // }
  }
  return min;
}
export function getDates(data, days, dates) {
  // Ninguna de estas fechas tiene timezone
  let daysSet = new Set(
    data[0].map((d) => d._id).concat(data[1].map((d) => d._id))
  );
  let daysList = Array.from(daysSet.values());
  let min = findMin(daysList);
  let max = dates && dates.length ? moment(dates[1]) : moment();
  let range = max.diff(min, "days");
  let dateList = [min.format("YYYY-MM-DD")];
  let i = 0;
  let cur = min.clone();
  for (i; i < range; i++) {
    cur = cur.add(1, "d");
    dateList.push(cur.format("YYYY-MM-DD"));
  }
  return dateList;
}
// export function getDatesWithHours(data, days, dates) {
//
// }
export function fillBlankSpaces(list) {
  let newList = Array(list.length);
  let cur = null;
  let i = 0;
  for (i; i < list.length; i++) {
    if (list[i] !== null) {
      cur = list[i];
    }
    newList[i] = cur;
  }
  return newList;
}
