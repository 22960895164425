<template>
  <div class="home">
    <div style="width: 95%; height: 400px; margin: 30px auto 0 auto">
      <div class="title-chart">
        {{ title }}
      </div>
      <div>
        <select name="Moneda" v-model="currency" @change="onCurChange($event)">
          <option v-for="cur of currencies" :key="cur" :value="cur">
            {{ getLegend(cur) }}
          </option>
        </select>
      </div>
      <chart-container :is-loading="isLoading">
        <v-chart v-if="option && !isLoading" class="chart" :option="option">
        </v-chart>
      </chart-container>
      <date-range-selector-component
        :on-change="onDateRangeChange"
      ></date-range-selector-component>
    </div>
  </div>
</template>

<script>
import { getCurName } from "../utils/curUtils";
import { getLegend } from "../utils/curUtils";
import DateRangeSelectorComponent from "./DateRangeSelectorComponent";
import { getRatesByDateRange } from "../utils/apiUtils";
import {fillBlankSpaces, getDates} from "../utils/data";
import ChartContainer from "../components/ChartContainer";

export default {
  name: "BuySellLineChart",
  components: { DateRangeSelectorComponent, ChartContainer },
  props: {
    title: String,
    currencies: Array,
    initialSelectedCur: String,
  },
  mounted() {
    console.log(this.initialSelectedCur);
    this.getOptions(this.days);
  },
  data() {
    return {
      isLoading: false,
      currency: this.initialSelectedCur,
      days: 7,
      dateValue: [],
      option: null,
    };
  },
  methods: {
    getCurName,
    getLegend,
    onCurChange() {
      if (this.days) {
        this.getOptions(this.days);
      } else {
        this.getOptions(0, this.dateValue);
      }
    },
    onDateRangeChange(days, dateValue) {
      console.log("on date range change", days, " ", dateValue);
      this.days = days;
      this.dateValue = dateValue ? dateValue : [];
      this.getOptions(days, dateValue);
    },

    getOptions(days, dates) {
      const me = this;
      const tipos = ["Compra", "Venta"];
      me.isLoading = true;

      Promise.all([
        getRatesByDateRange(me.currency, days, dates, tipos[0]),
        getRatesByDateRange(me.currency, days, dates, tipos[1]),
      ])
        .then((res) => {
          let data = res.map((resp) => resp.data);
          const dateList = getDates(data, days, dates);

          data = data.map((items) => {
            const cleanArr = dateList.map((el) => ({ id: el, value: null }));
            for (const it of items) {
              const i = cleanArr.findIndex((el) => it._id === el.id);
              if (i > -1) cleanArr[i].value = it.median;
              else {
                console.log("fecha no encontrada", it);
              }
              cleanArr[i].value = it.median;
            }
            return cleanArr.map((el) => el.value);
          });
          // Fill blank spaces
          data = data.map((ratesByDate) => fillBlankSpaces(ratesByDate));

          me.option = {
            grid: {
              bottom: 30,
            },
            color: [
              "#1636c1",
              "#d2011a",
              "#05ce7c",
              "#f99b2b",
              "#182360",
              "#1b5b20",
              "#6b05ce",
              "#cccc06",
            ],
            // Make gradient line here
            legend: {
              data: tipos,
              show: true,
              top: 10,
            },
            tooltip: {
              trigger: "axis",
              // formatter: '{b} | {c} CUP'
            },
            xAxis: [
              {
                data: dateList,
                axisLabel: {
                  fontFamily: "Roboto-Medium",
                },
              },
              // {
              //   data: dateList,
              //   gridIndex: 1,
              // },
            ],
            yAxis: [
              {
                axisLabel: {
                  fontFamily: "Roboto-Medium",
                },
                max: function (value) {
                  return value.max + 5;
                },
                min: function (value) {
                  return value.min - 5;
                },
              },
              // {
              //   gridIndex: 1,
              // },
            ],

            series: data.map((el, i) => ({
              type: "line",
              showSymbol: true,
              smooth: false,
              name: tipos[i],
              data: el,
            })),
          };
          me.isLoading = false;
        })
        .catch((e) => {
          me.isLoading = false;
          console.log(e);
        });
    },
  },
};
</script>

<style scoped></style>
